@use 'pkg:bootstrap';
@use 'functions';
@use 'sass:color';

$station-row-background: rgb(174, 177, 176);

.build-history {
  color: #545a69;
  display: flex;
  font-size: 12pt;
  height: 100%;
  margin-top: 10px;

  .no-data {
    color: rgb(144, 138, 132);
    font-size: 25px;
    padding-top: 50px;
    text-align: center;
    width: 400px;

    &.no-history {
      margin: auto;
    }
  }

  .build-history-tabs {
    ul {
      border-bottom: 3px solid #ababab;
      display: flex;
      margin-bottom: 1rem;
    }
  }

  .app-export[disabled]:hover {
    background-color: rgb(144, 139, 133);
  }

  .build-history-row, .station-row, .task-row {
    cursor: pointer;

    &:hover {
      filter: brightness(90%);
    }
  }

  .selected {
    filter: brightness(80%);

    &:hover{
      filter: brightness(75%);
    }
  }

  .good {
    background: bootstrap.$success;
  }

  .bad {
    background: bootstrap.$danger;
    color: white;
  }

  .not-required {
    background: bootstrap.$light;
    color: inherit;
  }

  .started {
    background-color: color.adjust(#c8e5f3, $lightness: -15%);
    color: #545a69;
  }

  table.ember-grid {
    border: none;
    table-layout: fixed;
    text-align: left !important;

    thead {
      border-bottom: 1px solid #afa9a9;
      font-size: 14px;
      font-weight: bootstrap.$font-weight-bold;

      th {
        background: #f8f3f0;
        border: none !important;
        font-size: 10pt !important;
        text-align: left !important;
      }
    }

    tbody {
      td {
        border: none !important;
        font-size: 11pt !important;
        text-align: left !important;
      }
    }

    .app-serial-number {
      max-width: 200px;
      word-wrap: break-word;

      .search-child {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    td.good {
      max-width: 20px;
    }
  }

  #first-column {
    flex-grow: 1;
    margin-right: 25px;
    width: 332px;

    table {
      colgroup {
        col:first-child {
          width: 28px !important;
        }

        col:nth-child(2) {
          width: 100% !important;
        }

        col:last-child {
          width: 180px !important;
        }
      }
    }

    .build-history-grid {
      width: 100%;

      .ember-grid.table {
        width: 101%;
      }

      .ember-grid-component.table-responsive {
        overflow-x: hidden;
        overflow-y: scroll;
      }

      tbody {
        width: 100%;

        tr {
          width: 100%;
        }
      }
    }
  }

  #second-column {
    .part-build-details-wrapper {
      margin: 0;
      padding-left: 0;

      .wip-badge {
        background-image: functions.image-url('wip-badge.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        display: inline-flex;
        height: 30px;
        margin: 5px;
        width: 60px;
      }

      .build-history-serial-number {
        color: #535b68;
        font-size: 28pt;
        margin-top: -12px;
      }

      .part-build-details {
        display: flex;
        font-weight: 500;
        padding-bottom: 10px;

        .status-column {
          display: flex;
          flex-wrap: wrap;
          margin-right: 20px;

          .status-label {
            color: bootstrap.$gray-600;
            font-size: 12pt;
            font-weight: bootstrap.$font-weight-light;
            margin-right: 4px;
            text-transform: uppercase;
          }

          .status-value {
            color: #545a69;
            font-size: 12pt;

            &.good-text {
              color: #56a148;
            }

            &.bad-text {
              color: bootstrap.$danger;
            }
          }
        }
      }
    }

    .build-status-and-details {
      height: 100%;

      #station-column,
      #task-column {
        display: flex;
        width: 50%;

        .ember-grid.table {
          table-layout: auto !important;
        }
      }

      #station-column {
        float: left;
        padding-right: 25px;

        .station-grid {
          width: 100%;
        }
      }

      #task-column {
        .task-grid {
          width: 100%;

          .task-row {
            .tree-icon {
              display: inline-block;
              max-width: 1.125rem;
              vertical-align: top;
              z-index: 0 !important;

              &.empty-icon {
                display: none;
              }
            }

            .app-task-status.has-codes:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .marriages,
  .process-data {
    .station-grid,
    .process-data-grid {
      .process-data-timestamp-column {
        width: 200px;
      }

      .process-data-value-column {
        width: 180px;
      }

      .station-row {
        background: $station-row-background;
        color: white;

        &:hover {
          background: $station-row-background;
        }

        .station-row-label {
          display: flex;
          font-weight: bootstrap.$font-weight-bold;

          .app-station-description,
          .spacer,
          .lastest-timestamp {
            font-size: 13px;
          }
        }
      }

      .spacer {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .images {
    height: calc(100% - 112px);
    overflow-y: auto;

    .station-name {
      background: #afafaf;
      color: white;
      font-weight: bootstrap.$font-weight-bold;
      padding: 4px 19px;
    }

    .image-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 20px;
      padding-top: 35px;
      position: relative;

      .image-count {
        color: #e69e00;
        font-size: 14px;
        font-style: italic;
        left: 19px;
        position: absolute;
        text-transform: lowercase;
        top: 8px;
      }

      .image {
        background: #cfcac4;
        font-size: 16px;
        height: 245px;
        margin: 0 20px 35px 0;
        padding: 6px 14px;
        position: relative;
        width: 210px;

        .download-checkbox-wrapper {
          display: flex;

          .download-checkbox .form-check-label {
            font-weight: bootstrap.$font-weight-bold;
            left: -8px;
            margin-left: 8px;
            position: relative;
            top: 1px;
          }

          .download-checkbox.pass .form-check-label {
            color: #009a01;
          }

          .download-checkbox.fail .form-check-label {
            color: #d70000;
          }
        }

        .img-contain {
          height: 61%;
          margin: 0 auto;
          overflow: hidden;
          width: 100%;

          img {
            display: block;
            height: auto;
            object-fit: contain;
            width: 100%;
          }
        }

        .image-details {
          align-self: flex-end;
          font-size: 13px;
          line-height: 1.2;
          position: relative;
          top: 10px;
        }

        .download {
          bottom: 18px;
          position: absolute;
          right: 14px;

          a {
            cursor: pointer;

            .download-icon {
              background-image: url('/images/download-white.svg');
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }

  .marriages {
    .marriage-serial-number-column {
      width: 230px;
    }

    .marriage-timestamp-column {
      width: 180px;
    }

    .marriage-user-column {
      width: 100px;
    }

    .marriage-status-column {
      width: 100px;
    }

    table {
      thead {
        th {
          border-bottom: none;
        }
      }
    }
  }
}
