.component-and-or {
  img.and-or {
    margin-top: 10px;
    width: 50px;
  }

  .and-or-wrapper {
    color: #777;
    width: calc(100% - 58px);

    .or-container {
      .or-group {
        left: 8px;

        &.or1-select {
          margin-bottom: 13px;
        }
      }
    }
  }
}
