@use 'pkg:bootstrap';

.component-bi-total-cycles-label {
  .total-cycles {
    color: bootstrap.$success;
    font-weight: bolder;
  }

  .label {
    color: bootstrap.$gray-600;
    text-transform: uppercase;
  }

  .total-rejects {
    color: bootstrap.$danger;
    font-weight: bolder;
    padding-right: 25px;
    text-transform: uppercase;
  }
}
