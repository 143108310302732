@use 'pkg:bootstrap';

.component-bi-report-dropdown {
  .ember-power-select-trigger {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: bootstrap.$gray-900;
    cursor: pointer;
    font-size: bootstrap.$h5-font-size;
    font-weight: bootstrap.$font-weight-semibold;
    line-height: normal;
    min-height: auto;
    padding: 0 1.4rem 0 0;

    &:hover {
      color: bootstrap.$gray-600;
    }
  }

  .ember-power-select-placeholder {
    color: bootstrap.$gray-400;
    font-size: 12px;
    letter-spacing: 1px;
  }
}
