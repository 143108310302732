.component-split-view {
  display: grid;

  .gutter-col {
    &::after {
      background: url('/images/icons/scrub-bar-vertical.svg') no-repeat center;
      background-size: 0.5rem;
      content: '';
      cursor: ew-resize;
      display: block;
      grid-row: 1/-1;
      height: 100%;
      opacity: 0.3;
    }

    &:hover {
      &::after {
        opacity: 0.6;
      }
    }
  }

  @for $i from 1 through 5 {
    .gutter-#{$i} {
      grid-column: $i * 2;
    }
  }
}

.jem-task-grid-wide {
  grid-column: 3 / -1;
}

.full-width-grid {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  grid-template-rows: auto;
}

.nested-grid {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
}
