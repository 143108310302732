@use 'pkg:bootstrap';

$levels: success, danger, warn, info;

$disabled-opacity: 0.5;

$table-body: #fff;
$table-border: 1px solid bootstrap.$gray-600;

$filter-white: brightness(0) invert(1);
$filter-black: brightness(0);
$filter-brand: brightness(0) saturate(100%) invert(20%) sepia(17%) saturate(2000%) hue-rotate(147deg) brightness(112%);
