@use 'sass:color';
@use 'pkg:bootstrap';

.serial-number-search-criteria {
  height: 38px;
}

.bom-manager {
  color: #545a69;
  height: calc(100% - 38px);

  .outer-detail {
    width: 70%;
  }

  .serial-number-status-grid {
    width: 30%;

    th {
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
    }

    td {
      border: none !important;
      min-height: 38px;
    }

    tr.is-invalid {
      color: bootstrap.$danger;
    }

    colgroup {
      col:nth-child(2) {
        width: 140px !important;
      }

      col:nth-child(3) {
        width: 25px !important;
      }
    }

    .status-icon {
      min-width: 20px;
    }

    .bom-manager-dropdown.remove-caret::after {
      display: none;
    }
  }

  .serial-number-options-tabs {
    margin-bottom: 0;
    margin-left: 25px;

    .nav-item {
      margin-right: 10px;
    }

    .nav-item:nth-of-type(1) {
      margin-right: 30px;
    }

    .nav-link {
      padding-top: 0;
    }
  }

  .serial-number-options-panes {
    height: inherit;
    padding-left: 25px;

    .ember-view {
      height: inherit;
    }

    #serial-num-data > .ember-view,
    .pane-grid,
    .tab-content {
      height: 100%;
    }

    a {
      color: #545a69;
    }

    .warning {
      --bs-table-bg: color.scale(bootstrap.$warning, $alpha: -50%);

      background-color: color.scale(bootstrap.$warning, $alpha: -50%);
      color: bootstrap.$input-color;

      &:hover {
        background-color: color.scale(bootstrap.$warning, $alpha: -40%);
      }

      a {
        color: bootstrap.$input-color;
      }
    }

    .component-panes-serial-number-variables {
      th.sn-variable {
        width: 33%;
      }

      th.sn-value {
        width: 66%;
      }
    }

    .component-panes-serial-number-options {
      .app-station-component {
        a {
          color: black;
        }
      }
    }
  }

  .serial-number-details {
    font-weight: 500;
    height: 80px;
    margin: 0 0 10px 25px;
    width: calc(100% / 1.5 + 120px);

    .status-label {
      color: bootstrap.$gray-600;
      margin-right: 10px;
    }

    .status-value {
      color: #545a69;
    }

    a.serial-number {
      color: #545a69;
      font-size: 28pt;
      text-decoration: underline;
    }
  }
}
