@use 'sass:color';
@use 'pkg:bootstrap';

.bom-source-tasks-popup {
  .list-group-item {
    padding: 0.5rem 1rem;

    .task-icon {
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
    }
  }
}

.component-options-panel {
  .component-buttons {
    background: color.adjust(bootstrap.$gray-700, $lightness: 20%);
    height: 35px;
    text-align: right;
    width: 100%;
  }

  .app-component {
    .selected {
      background: #ededed;
    }
  }
}

.app-bom-source, .component-options-panel {
  .move-col {
    min-height: 0.75rem;
    width: 4rem;
  }
}
