@use 'sass:color';
@use 'pkg:bootstrap';
@use 'sass:map';

.jem-task-row {
  background-color: bootstrap.$white;
  cursor: pointer;
  margin-left: 0.5rem;
  transition: all .1s;

  &.selected {
    margin-left: 0;
  }

  &.selected, &:hover {
    background-color: bootstrap.$gray-200;
  }

  &.success {
    background-color: color.scale(bootstrap.$success, $lightness: -15%, $saturation: -25%);

    &.selected, &:hover {
      background-color: color.scale(bootstrap.$success, $lightness: -10%, $saturation: -20%);
    }

    .text-gray-900, .text-primary {
      @extend .text-white;
    }

    .text-gray-800 {
      @extend .text-gray-200;
    }

    .task-icon, .complex-component-icon {
      filter: brightness(0) invert(1);
    }
  }

  &.danger {
    background-color: color.scale(bootstrap.$danger, $lightness: -20%, $saturation: -25%);

    &.selected, &:hover {
      background-color: color.scale(bootstrap.$danger, $lightness: -10%, $saturation: -20%);
    }

    .text-gray-900, .text-primary {
      @extend .text-white;
    }

    .text-gray-800 {
      @extend .text-gray-200;
    }

    .task-icon, .complex-component-icon {
      filter: brightness(0) invert(1);
    }
  }

  &.info {
    background-color: color.scale(bootstrap.$info, $lightness: 20%);

    &.selected, &:hover {
      background-color: color.scale(bootstrap.$info, $lightness: 5%);
    }
  }

  &.warning {
    background-color: bootstrap.$warning;

    &.selected, &:hover {
      background-color: color.scale(bootstrap.$warning, $lightness: -10%);
    }
  }

  &.not-required > td {
    background-color: #ccc;

    &.selected, &:hover {
      background-color: color.adjust(#ccc, $lightness: -7%);
    }
  }

  > td {
    vertical-align: middle;
  }

  .task-icon {
    background-repeat: no-repeat;
    background-size: 1.375rem 1.375rem;
    flex-shrink: 0;
    height: 1.375rem;
    margin: auto 0;
    width: 1.375rem;
  }

  .complex-component-icon {
    filter: brightness(0);
  }

  td.jem-description {
    &.overflow-visible {
      overflow: visible;
    }

    .jem-process-data {
      align-items: center;
      display: flex;
      font-size: map.get(bootstrap.$font-sizes, 4);
      font-weight: 100;
      margin-bottom: 5px;
      margin-top: 5px;

      input[type='text'] {
        width: 100%;
      }

      .process-data-bit input[type='radio'] {
        margin: 4px 5px 0;
      }

      .process-data-name {
        font-style: italic;
        margin-right: 10px;
      }

      .process-data-value {
        width: 100%;
      }
    }
  }
}
