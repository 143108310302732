$description-background-color: #D9EDF7;
$description-border-color: #BCE8F1;
$description-text-color: #31708F;

@if $theme-name == 'dark' {
  $description-background-color: rgba(0, 170, 255, .2);
  $description-text-color: #AAD1E4;
  $description-border-color: #346F7B;
}

$description-border: 1px solid $description-border-color;

.query-builder p.filter-description {
  margin: $rule-padding 0 0 0;
  background: $description-background-color;
  border: $description-border;
  color: $description-text-color;
  border-radius: $item-border-radius;
  padding: #{$rule-padding * .5} $rule-padding;
  font-size: .8em;
}
