@use 'sass:color';
@use 'pkg:bootstrap';

$btm-control-height: 40px;

.component-work-instruction-editor-tool-properties {
  height: 100%;
  padding: 0.5rem;

  input:focus {
    outline: none;
  }

  .tool-properties {
    height: 100%;
  }

  .folder-title {
    margin-bottom: 0.5rem;
  }

  .scroll-container {
    height: calc(100% - #{$btm-control-height});
    padding-left: 1px;
    padding-right: 10px;
  }

  .property-button,
  .property-button-accented {
    background-color: white;
    border: 1px color.adjust(white, $lightness: -30%) solid;
    margin-bottom: 10px;
    text-transform: uppercase;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .property-button-accented {
    background-color: bootstrap.$warning;
    border: 1px color.adjust(bootstrap.$warning, $lightness: -30%) solid;
    color: white;
  }

  .property-label {
    color: color.adjust(white, $lightness: -70%);
    font-size: 14px;
    margin-bottom: 0;
  }

  .property-flex-row,
  .property-row {
    align-items: center;
    margin-top: 10px;
    width: 100%;

    label {
      width: auto;
    }
  }

  .property-flex-row {
    display: flex;

    > * {
      margin-right: 10px;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }

    &.w-child-auto > * {
      width: auto;
    }

    .property-flex-col {
      width: 50%;
    }
  }

  .property-button-group {
    display: flex;
    width: 100%;

    button {
      align-items: center;
      background-color: white;
      border: 1px color.adjust(white, $lightness: -30%) solid;
      border-radius: 0;
      border-right: 0;
      color: color.adjust(white, $lightness: -60%);
      display: flex;
      font-size: 16px;
      height: 26px;
      justify-content: center;
      width: 100%;

      &:first-of-type {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        border-bottom-right-radius: 4px;
        border-right: 1px color.adjust(white, $lightness: -30%) solid;
        border-top-right-radius: 4px;
      }

      &:hover,
      &.selected {
        background-color: color.adjust(white, $lightness: -15%);
      }
    }
  }

  .properties-btm-controls {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
    z-index: 10;

    .square-button {
      white-space: normal;

      &.request-approval-button {
        width: 80px;

        &.requested {
          width: 86px;
        }
      }
    }
  }

  .x-file-input {

    /* stylelint-disable-next-line selector-class-pattern */
    &.x-file-input--disabled {
      opacity: 0.5;

      label {
        cursor: not-allowed;
      }
    }

    label {
      width: 100%;
    }
  }

  .size-group {
    .size-display-container {
      color: bootstrap.$gray-600;
      display: flex;
      justify-content: space-between;
      margin-top: -4px;
    }

    .size-input-container {
      display: flex;
      justify-content: space-between;
      margin-top: 0;

      input {
        width: 40%;
      }

      .keep-aspect-ratio {
        font-size: 14px;
        line-height: 30px;

        .icon {
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .file-name {
    color: bootstrap.$dark;
    font-size: 12px;
    font-style: italic;
    font-weight: bootstrap.$font-weight-light;
  }

  .file-name-remove {
    color: bootstrap.$dark;
    cursor: pointer;
    font-size: 26px;
    line-height: 16px;
  }
}
