@use 'pkg:bootstrap';

.eflex-navigate-tabs {
  background-color: white;
}

.eflex-tabs {
  border-bottom: 0.5px solid bootstrap.$gray-400;
  padding-left: 0;
  text-transform: capitalize;

  .eflex-tab:not(:last-child) {
    margin-right: 35px;
  }

  .eflex-tab {
    cursor: pointer;
    display: inline;
    font-weight: 600;

    a {
      color: bootstrap.$gray-600;
      margin-bottom: -1.5px;
      padding-bottom: 0.625rem;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .nav-link {
    color: inherit;
    padding-bottom: 0.3rem;

    &:not(.active) {
      &:hover {
        color: bootstrap.$gray-900;
      }
    }

    &.active {
      border-bottom: 3px solid bootstrap.$primary;
      color: bootstrap.$primary;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}

.tab-pane.active {
  height: 100%
}
