@use 'sass:color';
@use 'sass:map';
@use 'pkg:bootstrap';

.no-chrome-page,
.chrome-page {
  display: grid;
  height: 100vh;
}

.chrome-page {
  background-color: bootstrap.$gray-100;
  grid-template-areas:
    'header nav-bar app-bar'
    'header nav-bar workspace'
    'header nav-bar footer';
  grid-template-columns: auto auto 1fr;
  grid-template-rows: 2.9375rem 1fr auto;

  .workspace {
    display: grid;
    grid-template-areas:
      'title-bar edit-tree'
      'tab-bar edit-tree'
      'main-workspace edit-tree';
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto 1fr;
    min-height: 0;

    &.business-intelligence {
      background: bootstrap.$gray-900;
      padding: 30px 0 0;
    }

    .title-bar {
      grid-area: title-bar;
    }

    .tab-bar {
      grid-area: tab-bar;
    }

    .edit-tree {
      grid-area: edit-tree;
    }

    .main-workspace {
      grid-area: main-workspace;
      min-height: 0;
    }
  }

  .app-bar {
    grid-area: app-bar;
    z-index: map.get(bootstrap.$zindex-levels, 'top-menu');
  }

  .nav-bar {
    grid-area: nav-bar;
  }
}

.no-chrome-page {
  background: bootstrap.$gray-800;
  grid-template-areas:
    'header'
    'workspace'
    'footer';
  grid-template-rows: auto minmax(0, 1fr) auto;

  .main-footer {
    @extend .bg-gray-900, .text-gray-300;

    flex-wrap: wrap;
    width: 100%;

    .ember-power-select:focus,
    .ember-power-select-trigger:focus,
    .btn:focus {
      outline: none;
    }

    span.active {
      color: bootstrap.$info;
    }
  }
}

.workspace {
  grid-area: workspace;
  position: relative;
}

.main-header {
  grid-area: header;

  .header-menu {
    display: flex;
  }
}

.main-footer {
  grid-area: footer;
  z-index: 999;
}

.toggle-nav {
  border-radius: 15px;
  color: lightgray;
  cursor: pointer;
  font-size: 13px;

  i {
    margin-right: 15 px;
  }

  &:hover {
    color: ghostwhite;
    transition: all 0.3s linear;
  }
}

.nav-tabs > li > a {
  color: color.adjust(bootstrap.$gray-700, $lightness: 27%);
  outline: 0;
  padding: 0.5rem 1rem;
}

.model-specific {
  color: bootstrap.$primary !important;

  @at-root th#{&}, & label, & .header-row, & .nested-header {
    color: bootstrap.$primary !important;
  }

  input:not([type='checkbox']),
  select,
  input.is-valid:not([type='checkbox']) {
    border: 1px solid bootstrap.$primary;
    color: bootstrap.$primary;
  }

  .input-group-prepend {
    border-color: bootstrap.$primary;
  }

  .ember-power-select-trigger:not([aria-disabled='true']) {
    border-color: bootstrap.$primary;
    color: bootstrap.$primary;
  }

  input[type='checkbox'] + label::before {
    border: 1px solid bootstrap.$primary;
  }
}

.no-javascript {
  color: '#FF0000';
  text-align: center;
}

