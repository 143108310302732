@use 'pkg:bootstrap';
@use 'sass:map';

.gridster-wrapper {
  height: 100%;

  .gridster {
    height: 100%;
    z-index: map.get(bootstrap.$zindex-levels, 'task-grid');

    .gs-w {
      z-index: initial;
    }

    .preview-holder {
      border: dashed #908a84 4px;
    }

    .gridster-cards {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  .gridster-card-holder {
    &.is-invalid {
      .task-card,
      .task-card:hover {
        --bs-card-border-color: #{bootstrap.$danger};
        --bs-card-border-width: 1px;
        --bs-card-inner-border-radius: 0;

        box-shadow: 0 0 4px 2px bootstrap.$danger;
      }
    }

    &[isselected] .card {
      --bs-card-border-color: #{bootstrap.$primary};
      --bs-card-border-width: 2px;
      --bs-card-inner-border-radius: 0;

      background-color: rgba(bootstrap.$primary, 0.25);
    }
  }

  .task-card {
    box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 15%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &:hover {
      box-shadow: 0.25rem 0.5rem 0.5rem rgba(0, 0, 0, 15%);
      transition: box-shadow 0.2s;
    }

    .task-icon {
      background-position: 2px 2px;
      background-repeat: no-repeat;
      background-size: 25px 25px;
      display: inline-block;
      height: 30px;
      line-height: 13px;
      margin: 0;
      min-width: 30px;
      vertical-align: middle;
      width: 30px;
    }

    .icon-drag-drop {
      line-height: 1.5;
      width: 10px;
    }

    .card-header.disabled {
      background: bootstrap.$gray-300;
      cursor: default;
    }

    &:hover .task-resize-handle {
      opacity: 1;
    }
  }

  .task-resize-handle {
    background: bootstrap.$border-color;
    border: 1px solid bootstrap.$gray-200;
    border-radius: 2px;
    bottom: 0;
    cursor: row-resize;
    height: 12px;
    left: 50%;
    margin: 0 auto -4px;
    margin-left: -15px;
    opacity: 0;
    padding: 0 2px;
    position: absolute;
    width: 30px;

    &::before,
    &::after {
      border-bottom: 1px solid bootstrap.$gray-200;
      border-top: 1px solid #fff;
      content: '';
      height: 2px;
      left: 0;
      margin: 0 3px;
      position: absolute;
      width: 22px;
    }
  }

  .task-resize-handle-y {
    cursor: grab;
    padding: 5px 10px;
  }
}
