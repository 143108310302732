@use 'sass:map';
@use 'pkg:bootstrap';
@use 'variables';

$ember-grid-border: 1px solid bootstrap.$gray-200;

.component-ember-grid {
  height: 100%;
  position: relative;

  .ember-grid {
    border: none;
    margin-bottom: 0;
  }

  .ember-grid-component {
    height: 100%;

    .ember-grid {
      border-collapse: separate;
      border-spacing: 0;
      text-align: left;

      td {
        border: none;
        vertical-align: middle;

        label {
          margin-bottom: 0;
        }
      }

      th {
        position: sticky;
        top: 0;
      }

      th:has(div.form-check),
      th:empty {
        &::after {
          border: none;
        }
      }

      tr {
        &:hover {
          background-color: bootstrap.$gray-100;
        }
      }
    }
  }

  .ember-grid-action-row {
    cursor: pointer;

    &:hover {
      background-color: bootstrap.$gray-100;
      color: bootstrap.$gray-800;
    }
  }

  .ember-grid-action {
    color: bootstrap.$gray-800;
    cursor: pointer;

    &:hover {
      color: bootstrap.$gray-800;
    }
  }

  .ember-grid-component .ember-grid th,
  .ember-grid-header-cell {
    background-color: white;
    border-color: $ember-grid-border;
    border-style: none;
    color: bootstrap.$gray-600;
    cursor: pointer;
    font-size: map.get(bootstrap.$font-sizes, 5);
    font-weight: bold;
    letter-spacing: 0.5px;
    padding: 9px;
    text-align: left;
    text-shadow: 0 1px 0 bootstrap.$white;
    text-transform: uppercase;
    vertical-align: middle;
    z-index: map.get(bootstrap.$zindex-levels, 'table-header');

    &::after {
      border-bottom: variables.$table-border;
      content: '';
      display: block;
      margin-top: 0.3rem;
    }
  }

  .ember-grid-header-cell {
    cursor: default;
    text-align: left;
  }

  &.pane-grid {
    table {
      tbody {
        td {
          border: none !important;
          font-size: 11pt;
          text-align: left;
        }

        tr:last-child {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  .load-wrapper {
    display: inline-flex;
    padding: 5px;
    width: 100%;

    .app-load-more {
      background-color: bootstrap.$warning;
      color: bootstrap.$white;
      margin: 0 auto;
      text-transform: uppercase;
    }
  }
}

.component-ember-grid.ember-grid-dark {
  .ember-grid-component {
    .ember-grid {
      th {
        background: bootstrap.$gray-900;
      }
    }

    tr {
      &:hover {
        background-color: bootstrap.$gray-300;
        color: bootstrap.$gray-800;
      }

      &.ember-grid-action-row {
        cursor: pointer;

        &:hover {
          background-color: bootstrap.$gray-100;
          color: bootstrap.$gray-800;
        }
      }
    }
  }

  .ember-grid-component .ember-grid th,
  .ember-grid-header-cell {
    color: bootstrap.$white;
    text-shadow: none;
  }
}
