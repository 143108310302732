@use '../functions';
@use 'pkg:bootstrap';

.jem-station-dropdown {
  min-width: 0;

  .ember-power-select {
    align-items: center;
    display: flex;
    height: 3.375rem;
  }

  .ember-power-select-selected-item {
    .tree-icon {
      display: none;
    }
  }

  .ember-power-select-group-name::before {
    background-image: functions.image-url('tree-area-icon.svg');
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 15px;
    margin: 0 5px;
    position: relative;
    top: 4px;
    width: 15px;
  }

  .ember-power-select-options {
    li.ember-power-select-option {
      align-items: center;
      display: flex;
      height: 24px;

      @media (min-width: 1801px) {
        height: 32px;
      }
    }

    .tree-item {
      align-items: center;
      display: flex;
      height: 24px;
      width: auto;

      span {
        vertical-align: middle;
      }

      .tree-icon {
        vertical-align: middle;
      }
    }
  }

  .ember-power-select-trigger {
    color: bootstrap.$white !important;
    font-size: 2rem;

    .ember-power-select-status-icon {
      background: functions.image-url('dropdown-arrow.svg') no-repeat center;
    }
  }

  .tree-icon {
    display: inline-block;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}
