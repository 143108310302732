.component-work-instruction-editor-tool-buttons {
  .editor-controls {
    .has-upload-image {
      .x-file-input {
        display: block;
        width: 100%;

        label {
          width: 100%;
        }
      }
    }
  }
}
