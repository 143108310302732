.auto {
  cursor: auto;
}

.default {
  cursor: default;
}

.pointer {
  cursor: pointer !important;
}

.text {
  cursor: text;
}

.copy {
  cursor: copy;
}

.move {
  cursor: move;
}

.not-allowed {
  cursor: not-allowed !important;
}

.help {
  cursor: help;
}

