@use 'pkg:bootstrap';
@use 'sass:color';

.jem-page {
  .jem-content-area {
    min-height: 0;

    .jem-pane-header {
      height: 3.5rem;
    }

    &.single-task-view {
      $task-info-width: 300px;

      .main-info-section {
        .work-instruction {
          height: 100%;
          width: calc(100% - #{$task-info-width});
        }

        .jem-image-container {
          height: 100%;

          img {
            padding: 0;
          }
        }
      }

      .task-info {
        color: #fff;
        height: 100%;
        position: relative;
        width: $task-info-width;
      }

      .detail-text-value fw-bold text-gray-900 {
        color: #fff;
        font-size: 2rem;
        font-weight: normal;

        &.btn {
          color: bootstrap.$input-color;
        }
      }

      .timer-section {
        margin-top: 0;
      }
    }

    .task-details {
      >* {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &:last-child:empty {
          margin-bottom: -1rem;
        }
      }

      .jem-bolts {
        >* {
          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          &:last-child:empty {
            margin-bottom: -1rem;
          }
        }
      }
    }
  }

  .jem-action-controller {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-width: 14rem;

    button {
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;
      height: 100%;
      max-height: 5rem;
      min-height: 2rem;
      padding: 0;
      text-transform: uppercase;
      width: 100%;

      &.hold,
      &.ok-button {
        color: bootstrap.$gray-900;
      }
    }

    .btn-hold {
      @include bootstrap.button-variant(bootstrap.$warning, bootstrap.$warning, bootstrap.$gray-900, color.adjust(bootstrap.$warning, $lightness: -20%), color.adjust(bootstrap.$warning, $lightness: -20%));

      color: bootstrap.$gray-900;
    }

    .btn-stop {
      @include bootstrap.button-variant(bootstrap.$orange, bootstrap.$orange, bootstrap.$gray-900, color.adjust(bootstrap.$orange, $lightness: -10%), color.adjust(bootstrap.$orange, $lightness: -10%));

      color: bootstrap.$gray-900;
    }

    .jem-ok-button-portal {
      max-height: 10rem;
      min-height: 6rem;

      button {
        max-height: inherit;
        min-height: inherit;
      }

      .extra-ok-button {
        display: none;

        &:only-child {
          display: block;
        }
      }
    }
  }
}

.jem-task-display {
  pointer-events: none;
}

.jem-view-toggles {
  button.list-view,
  button.single-view {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 6.5px;
    opacity: 0.5;
    outline: none;
    padding: 0;

    &.active {
      border: 2px solid bootstrap.$primary;
      border-radius: 4px;
      opacity: 1;
    }

    >i {
      $list-view-toggle-size: 38px;

      display: block;
      height: 38px;
      width: $list-view-toggle-size;
    }
  }
}

.app-jem-oee-button {
  .status {
    &.running {
      background-color: bootstrap.$success;
    }

    &.faulted {
      background-color: bootstrap.$danger;
    }

    &.starved,
    &.blocked,
    &.changeover,
    &.breaks {
      background-color: bootstrap.$warning;
    }
  }
}
