@use 'sass:list';

$good-font-sizes: 8.5vw 7vw 5.5vw;
$bad-font-sizes: 5vw 4vw 3.2vw;

.component-bi-andon-production-station {
  width: 99%;

  .production-tabulator {
    height: 92%;
  }

  .tabulator-cell[tabulator-field='station'] {
    font-size: 2.75rem;
    font-weight: lighter;
  }

  .tabulator-cell[tabulator-field='partCounts'] {
    .part-count-good {
      color: #a4e886;
      font-size: 4.5rem;
    }

    .part-count-bad {
      color: #e67a81;
      font-size: 2.75rem;
    }
  }

  .total-cycles-label-wrapper {
    .total-cycles {
      font-size: 4.5rem;
    }

    .total-rejects {
      font-size: 2.75rem;
    }
  }

  @for $i from 1 through 3 {
    &.station-count-#{$i} {
      .tabulator-cell[tabulator-field='station'],
      .tabulator-cell[tabulator-field='partCounts'] .part-count-bad,
      .total-cycles-label-wrapper .total-rejects {
        font-size: list.nth($bad-font-sizes, $i);
      }

      /* stylelint-disable-next-line no-duplicate-selectors */
      .tabulator-cell[tabulator-field='partCounts'] .part-count-good ,
      .total-cycles-label-wrapper .total-cycles {
        font-size: list.nth($good-font-sizes, $i);
      }
    }
  }
}
