@use 'pkg:bootstrap';

$scrollbar-color: bootstrap.$gray-300;

.eflex-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: $scrollbar-color bootstrap.$white;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background: transparent;
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 10px;

    &:hover {
      background: $scrollbar-color;
      background-clip: padding-box;
    }
  }
}
