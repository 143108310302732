@use 'sass:color';

// update style guide after changing colors
// Brand Colors
$eagle-green: #025064;
$interactive-blue: #0D6B9E;
$non-photo-blue: #a9dcf8;
$pearl-aqua: #90d2b5;
$salmon: #ff8873;

// Brand Darken
$darken-interactive-blue: #0B547C;
$darken-non-photo-blue: #86aec4;
$darken-english-vermillion: #b23e3c;
$darken-ufo-green: #17b252;
$darken-maize: #d2a102;

// States
$english-vermillion: #d8524e;
$maize: #ffcb57;
$ufo-green: #1bcf5f;
$tangerine-orange: #ff7a00;

// Grayscale
$gray-100: #f5f7fa;
$gray-200: #e5eced;
$gray-300: #b1baba;
$gray-400: #98a0a2;
$gray-500: #7e8686;
$gray-600: #616868;
$gray-700: #4c5252;
$gray-800: #3b3e3e;
$gray-900: #252727;

$brand: $eagle-green;
$primary: $interactive-blue;
$secondary: #fff;
$success: $ufo-green;
$danger: $english-vermillion;
$warning: $maize;
$info: $non-photo-blue;
$light: $gray-100;
$dark: $gray-900;
$orange: $tangerine-orange;
$white: #fff;

$custom-colors: (
  "brand": $brand,
);

@use 'pkg:bootstrap' with (
  $enable-cssgrid: true,
  $enable-negative-margins: true,

  $body-bg: $gray-700,
  $grid-gutter-width: 1rem,

  /* Fonts*/
  $rfs-base-value: 1rem,
  $rfs-breakpoint: 1400,
  $rfs-class: enable,

  $font-family-sans-serif: 'Source Sans Pro',
  $font-weight-bold: 700,

  $gray-100: $gray-100,
  $gray-200: $gray-200,
  $gray-300: $gray-300,
  $gray-400: $gray-400,
  $gray-500: $gray-500,
  $gray-600: $gray-600,
  $gray-700: $gray-700,
  $gray-800: $gray-800,
  $gray-900: $gray-900,

  $primary: $primary,
  $secondary: $secondary,
  $success: $success,
  $danger: $danger,
  $warning: $warning,
  $info: $info,
  $light: $light,
  $dark: $dark,
  $orange: $orange,
  $white: $white,

  $theme-colors: (
    primary: $primary,
    secondary: $secondary,
    secondary-dark: $gray-900,
    success: $success,
    danger: $danger,
    warning: $warning,
    info: $info,
    light: $light,
    dark: $dark,
    orange: $orange,
    white: $white,
    gray-100: $gray-100,
    gray-200: $gray-200,
    gray-300: $gray-300,
    gray-400: $gray-400,
    gray-500: $gray-500,
    gray-600: $gray-600,
    gray-700: $gray-700,
    gray-800: $gray-800,
    gray-900: $gray-900,
    brand: $brand,
  ),

  $font-size-lg: 2rem,

  $h1-font-size: 4rem,
  $h2-font-size: 2.5rem,
  $h3-font-size: 2.25rem,
  $h4-font-size: 2rem,
  $h5-font-size: 1.5rem,
  $h6-font-size: 1.25rem,
  $headings-font-weight: 700,

  $font-sizes: (
    1: 1.5rem,
    2: 1.25rem,
    3: 1rem,
    4: 0.875rem,
    5: 0.75rem,
    6: 0.625rem,
  ),

  $border-radius: 0.1875rem,
  $border-radius-sm: 0.1875rem,
  $border-radius-lg: 0.1875rem,

  $min-contrast-ratio: 2,

  /* Tables */
  $table-bg: $white,
  $table-cell-padding-y-sm: 0.3rem,
  $table-cell-padding-x-sm: 0.5rem,
  $table-cell-vertical-align: center,

  /* Legends */
  $legend-font-size: 1rem,
  $legend-font-weight: 700,

  /* Link */
  $link-shade-percentage: 30,

  /* For Inputs & Buttons */
  $input-btn-padding-x-lg: 1rem,
  $input-btn-padding-y-lg: 0.375rem,
  $input-btn-font-size-lg: 1.5rem,
  $input-btn-padding-x: 0.625rem,
  $input-btn-padding-x-sm: 0.438rem,
  $input-btn-font-size-sm: 0.875rem,

  /* Inputs */
  $input-bg: $white,
  $input-color: $gray-700,
  $input-border-color: $gray-900,
  $input-focus-border-color: $gray-900,
  $input-placeholder-color: $gray-400,
  $input-disabled-bg: #fff,
  $input-focus-box-shadow: 0 0 0 4px color.adjust($primary, $alpha: -0.4),

  /* Buttons */
  $btn-color: $gray-800,
  $color-contrast-dark: $white,
  $color-contrast-light: $gray-800,
  $btn-disabled-opacity: 0.25,
  $btn-hover-bg-shade-amount: 10%,
  $btn-hover-border-shade-amount: 10%,
  $btn-active-border-shade-amount: 0%,
  $btn-active-border-tint-amount: 0%,
  $btn-font-weight: 700,
  $btn-border-width: 1.5px,

  /* Labels */
  $form-label-margin-bottom: 0.25rem,
  $form-label-font-size: 0.875rem,
  $form-label-font-weight: 600,
  $form-label-color: $gray-800,

  /* Dropdowns */
  $dropdown-bg: $white,
  $dropdown-dark-bg: $gray-700,
  $dropdown-border-color: $gray-200,
  $dropdown-dark-border-color: $gray-600,
  $dropdown-dark-color: $white,
  $dropdown-padding-x: 0,
  $dropdown-padding-y: 0,
  $dropdown-item-padding-x: 0.5rem,
  $dropdown-item-padding-y: 0.5rem,
  $dropdown-link-hover-bg: $gray-200,
  $dropdown-link-active-color: $gray-900,
  $dropdown-link-active-bg: $gray-200,
  $dropdown-dark-box-shadow: 0.15rem 0.15rem 1rem rgba(black, 0.5),
  $dropdown-dark-link-hover-bg: $gray-800,
  $dropdown-dark-link-active-color: $white,
  $dropdown-dark-link-active-bg: $gray-800,

  /* Forms*/
  $form-feedback-icon-valid: none,
  $form-feedback-valid-color: $gray-900,

  /* Checkboxes*/
  $form-check-input-border: 0.1rem solid $gray-400,
  $form-check-padding-start: 1.5rem,
  $form-check-input-width: 1rem,
  $form-check-min-height: 1rem,

  /* List Groups*/
  $list-group-bg: $white,

  /* Tabs*/
  $nav-link-padding-y: 0,
  $nav-link-padding-x: 0,

  /* Cards*/
  $card-spacer-y: 0,
  $card-spacer-x: 0.25rem,
  $card-bg: $white,
  $card-cap-bg: $primary,
  $card-cap-color: $white,
  $card-cap-padding-x: 0,
  $card-border-color: $gray-200,
  $card-border-radius: 4px,
  $card-inner-border-radius: 2px,

  /* Modals*/
  $modal-footer-margin-between: 0,
  $modal-content-bg: $white,
  $modal-content-border-color: $gray-800,
  $modal-content-border-width: 0,
  $modal-header-border-color: $gray-800,
  $modal-footer-bg: $white,
  $modal-footer-border-width: 0,
  $modal-sm: 450px,
  $modal-md: 600px,
  $modal-lg: 900px,
  $modal-xl: 1200px,
  $modal-inner-padding: 1.5rem,
  $modal-backdrop-bg: rgba($gray-900, 0.5),
  $modal-backdrop-opacity: 1,

  /* Progress*/
  $progress-height: 0.875rem,
  $progress-border-radius: 50rem,

  /* Badges*/
  $badge-font-weight: 600,
  $badge-font-size: 0.875rem,
  $badge-padding-x: 0.5rem,
  $badge-padding-y: 0.375rem,
  $badge-border-radius: 0.625rem,

  /* Pagination*/
  $pagination-bg: $white,

  /* Accordions*/
  $accordion-bg: $white,

  /* Popovers*/
  $popover-bg: $white,

  /* Tooltips */
  $tooltip-bg: $gray-900,
  $tooltip-color: $white,

  /* Thumbnails */
  $thumbnail-bg: $white,

  $zindex-levels: (
    n1: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    task-grid: 1,
    above-task-cards: 2,
    wie-delete-icon: 2,
    table-header: 3,
    title-bar: 4,
    dockable: 5,
    above-dock: 6,
    wie-context-menu: 10,
    side-panel: 1030,
    top-menu: 1035,
    modal-backdrop: 1040,
    modal: 1050,
    ajax-loader: 1051,
    popover: 1054,
    select-options: 1058,
    spinner: 1090,
  ),
);
