@use 'pkg:bootstrap';

.component-task-type-select {
  .add-task {
    @extend .text-primary, .border-primary, .border-1;

    cursor: pointer;

    &:hover {
      @extend .text-white;
    }
  }

  .task-dropdown {
    min-width: 300px;
  }

  .ember-power-select-options {
    max-height: 100%;
  }
}
