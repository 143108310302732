@use 'pkg:bootstrap';

.component-criteria-visor {
  .search-criteria {
    background-color: rgba(bootstrap.$gray-900, 75%);
    left: 0;
    width: 100%;

    &.eflex-criteria-visor {
      .visor-search {
        margin: (-10px) 0 10px;
      }

      button.btn-close {
        opacity: 1;
        position: absolute;
        right: 10px;
        top: 8px;
      }

      .criteria-auto-grid {
        column-gap: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
      }

      @media (max-width: 942px) {
        .criteria-auto-grid {
          grid-template-columns: 1fr;
        }
      }

      .filter-container {
        background: bootstrap.$white;
        border-radius: 3px;
        box-shadow: 2px 2px 3px;
        color: bootstrap.$gray-700;
        margin-bottom: 10px;
        min-width: 300px;
        padding: 10px;

        .search-box {
          background: bootstrap.$white;
          padding: 10px 0;

          input {
            width: 95%;
          }
        }

        h3 {
          color: bootstrap.$gray-700;
          margin: 0 0 15px;
          padding: 0;
        }

        label {
          color: bootstrap.$gray-700;
        }
      }
    }
  }
}
