@use 'sass:color';
@use 'pkg:bootstrap';
@use 'sass:map';

$ts-border: #b4b4b4;
$ts-search-border: #969696;
$ts-background: #f6f6f6;
$ts-search-backround: #d2d2d2;
$ts-hover: #e3e3e3;
$ts-divider: #d7d7d7;
$ts-padding: 0.25rem;

.tree-select {
  .ember-basic-dropdown-trigger {
    cursor: pointer;
    outline: none;
  }

  .ember-basic-dropdown-trigger[aria-disabled='true'] {
    cursor: not-allowed;

    .tree-select-trigger {
      .tree-select-trigger-target,
      .tree-select-trigger-clear,
      .tree-select-trigger-caret {
        background-color: color.adjust(#fff, $lightness: -6%);
      }
    }
  }

  .ember-basic-dropdown-trigger[aria-expanded='true'] {
    .tree-select-trigger {
      .tree-select-trigger-target {
        border-radius: 0.25rem 0 0;
      }

      .tree-select-trigger-caret {
        border-radius: 0 0.25rem 0 0;

        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  .tree-select-trigger {
    background-color: bootstrap.$input-bg;
    border: bootstrap.$input-border-color solid bootstrap.$input-border-width;
    border-radius: bootstrap.$input-border-radius;
    display: flex;
    font-size: bootstrap.$input-font-size;
    line-height: bootstrap.$input-line-height;
    max-height: calc(bootstrap.$input-height * 1.6);
    min-height: bootstrap.$input-height;
    padding: bootstrap.$input-padding-y bootstrap.$input-padding-x;
    position: relative;
    width: 100%;

    .tree-select-trigger-target {
      display: flex;
      flex-wrap: wrap;
      margin-right: auto;
      overflow-x: hidden;
      overflow-y: auto;

      .input-placeholder {
        background-color: inherit;
        color: bootstrap.$input-placeholder-color;
        cursor: inherit;
      }

    }

    .tree-select-trigger-clear {
      color: bootstrap.$input-placeholder-color;
      margin-right: 1rem;
    }
  }
}

.tree-select-position-below {
  &.tree-select-dropdown-content {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .tree-select-content {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.tree-select-position-above {
  &.tree-select-dropdown-content {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .tree-select-content {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

.tree-select-content {
  background-color: $ts-background;
  border: 0.0625rem solid $ts-border;
  overflow-x: hidden;
  padding: $ts-padding 0;

  .search {
    height: 1.875rem;
    padding: 0 $ts-padding;

    .form-control {
      border-color: $ts-border $ts-search-border $ts-border $ts-border;
      height: 1.875rem;
    }

    .input-group-append {
      height: 1.875rem;

      .input-group-text {
        background-color: $ts-search-backround;
        border-color: $ts-search-border;
        font-size: map.get(bootstrap.$font-sizes, 2);
        padding: 0;
        width: 1.875rem;

        .icon {
          color: #fff;
          margin-left: 0.1875rem;
        }
      }
    }
  }

  .check-all {
    position: relative;

    .label {
      font-weight: bold;
    }
  }

  .label {
    padding: $ts-padding;

    &:hover {
      background-color: $ts-hover;
    }
  }

  .label-text {
    font-size: map.get(bootstrap.$font-sizes, 3);
    margin-top: 0.125rem;
    vertical-align: top;
  }

  .expand-icon {
    margin-bottom: 0.1875rem;
    margin-left: 0.125rem;
    margin-right: 0.375rem;
    margin-top: 0.3125rem;
  }

  .no-expand .expand-icon {
    background-image: none;
  }

  .data-icon {
    height: 1.125rem;
    margin-top: 0.3125rem;
    width: 1.125rem;
  }

  .divider {
    border-top: 0.0625rem solid $ts-divider;
    bottom: 0;
    left: 1.3125rem;
    position: absolute;
    right: 1.3125rem;
  }
}

.tree-select.model-specific {
  .tree-select-trigger > div {
    border-color: bootstrap.$primary;
    color: bootstrap.$primary;
  }
}
