@use 'pkg:bootstrap';

.component-jem-instructions {
  background: bootstrap.$white;
  height: 100%;
  pointer-events: all;

  .eflex-tabs {
    background-color: bootstrap.$gray-900;
    border-bottom: none;
    color: bootstrap.$gray-200;
    flex-direction: row;
    margin-bottom: 0;
    padding-bottom: 0;

    li {
      align-items: center;
      color: bootstrap.$gray-200;
      display: flex;
      font-size: 20px;
      font-weight: normal;
      line-height: 46px;
      margin-right: 1rem !important;
      padding: 0.25rem 0.5rem;
    }

    a {
      color: bootstrap.$gray-300 !important;

      &.active {
        border: none;
        color: bootstrap.$white !important;
      }
    }

    .nav-link {
      display: inline;
      padding-bottom: 0;
    }
  }

  .jem-image-container {
    position: relative;

    &.full-screen {
      background-color: bootstrap.$gray-900;
      height: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1000;

      .threed-inner-wrapper {
        height: 100%;
        position: static;
      }
    }

    &.full-screen .full-screen-toggle {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1001;
    }

    .threed-outer-wrapper {
      height: 100%;
    }

    .threed-inner-wrapper {
      height: 80%;
      position: relative;
      top: 10%;
    }

    object,
    iframe,
    .threed-viewer {
      position: static !important;
    }
  }
}
