@use 'sass:color';
@use 'pkg:bootstrap';

$card-width: 113px;

.component-work-instruction-editor-library-task {
  .library-search {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  button {
    &:focus {
      box-shadow: none;
    }
  }

  button.clear {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 0;
    width: 30px;
  }

  button.search {
    cursor: pointer;
  }

  .library-carousel-container {
    color: bootstrap.$gray-600;
    display: flex;
    height: 170px;
    justify-content: center;
    overflow: hidden;
    padding: 5px;

    .carousel-arrow {
      align-items: center;
      display: flex;
      font-size: 20px;
      max-width: 10px;
      width: 10px;

      button {
        border: none;
        color: bootstrap.$gray-600;
        cursor: pointer;
        height: 100%;
        outline: none;
        padding: 0;

        &:active {
          color: color.adjust(bootstrap.$gray-600, $lightness: -20%);
        }
      }
    }
  }

  .folder-title {
    color: bootstrap.$gray-400;
    font-size: 12px;
    margin: 5px;
    max-width: $card-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-wie-folder {
    height: 75px;
    min-height: 75px;
  }

  .return-card {
    .library-card {
      border: none;
    }

    .library-return-card {
      margin-top: 17px;
    }

    .icon-wie-library-return {
      width: 70px;
    }
  }

  .library-card {
    background-color: rgba(bootstrap.$gray-100, 60%);
    color: bootstrap.$input-color;
    cursor: pointer;
    height: 148px;
    margin: 5px;
    max-height: 148px;
    padding: 5px;
    width: $card-width !important;

    &.active {
      background-color: color.adjust(white, $lightness: -10%);
    }

    &:hover,
    &.hovered {
      background-color: bootstrap.$gray-100;
    }

    .thumb {
      align-items: center;
      display: flex;
      height: 78px;
      justify-content: center;
    }

    img {
      margin-bottom: 4px;
      max-height: 75px;
      max-width: 100px;
    }

    .name {
      font-size: 12px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .info {
      font-size: 10px;
      line-height: 1.3;
    }

    .notes {
      display: none;
    }
  }
}
