@use 'pkg:bootstrap';

.component-jem-instructions-configuration {
  .task-library {
    margin-bottom: 0.5rem;
  }

  .image-wrapper {
    height: 34rem;
    width: 100%;

    .name {
      color: bootstrap.$gray-400;
      font-size: 14px;
      max-width: 100%;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .icon-dynamic {
      color: bootstrap.$primary;
      margin-left: 6px;
    }

    .image {
      min-height: 0;
      position: relative;
      width: 100%;

      .canvas-container {
        canvas.lower-canvas {
          position: relative !important;
        }
      }

      img {
        height: 100%;
      }

      object,
      iframe,
      canvas {
        height: 100% !important;
        max-height: none !important;
        max-width: none !important;
        width: 100%;
      }
    }
  }

  .links-container {
    display: inline;
    position: absolute;
    right: 0;
    top: 0;

    .edit {
      margin-bottom: 8px;
    }

    .edit i {
      color: bootstrap.$dark;
    }

    .edit:hover i {
      color: bootstrap.$primary;
    }
  }

  .placeholder-image {
    align-items: center;
    border: 4px dashed bootstrap.$gray-100;
    display: flex;
    flex-direction: column;
    height: 275px;
    padding-top: 1rem;
    width: 100%;

    i {
      color: bootstrap.$gray-100;
      font-size: 4.5em;
      padding-top: 1rem;
    }

    .placeholder-text {
      color: bootstrap.$gray-600;
      font-size: 16px;
      text-align: center;
    }

    .placeholder-icon {
      color: #d4d4d4;
      height: 72px;
      margin-top: 24px;
      width: 60px;
    }
  }

  .pdf-page {
    width: 50px;
  }
}
