@use 'pkg:bootstrap';
@use 'sass:map';

.jem-info-bar,
.jem-station-dropdown {
  .form-control {
    background-color: bootstrap.$gray-900;
    border-color: bootstrap.$gray-200;
    color: bootstrap.$white;
  }

  .form-control:hover {
    border-color: bootstrap.$gray-100;
  }

  .form-control:focus{
    border-color: bootstrap.$white;
  }

  .form-control::placeholder {
    color: bootstrap.$gray-400;
  }

  input:read-only {
    background-color: #{bootstrap.$gray-800} !important;
    border: none;
    color: bootstrap.$white !important;
  }

  label {
    color: bootstrap.$white !important;
  }

  .ember-basic-dropdown-trigger.form-control-lg {
    .ember-power-select-placeholder {
      font-size: 1.5rem;
    }
  }
}

.timer-text {
  line-height: 1;

  .timer-deci-seconds {
    height: 2rem;
  }
}

.jem-image-upload {
  display: block;
  margin: 0 auto;
}

.jem-content-container {
  height: calc(100% - 70px);
}

.jem-text-success {
  color: #0f6104 !important;
}

.jem-text-danger {
  color: bootstrap.$danger !important;
}

.jem-bg-success {
  background-color: bootstrap.$success;
}

.jem-bg-danger {
  background-color: bootstrap.$danger;
}

.jem-build-status {
  width: 15.5rem;
}

.jem-operation-quantity, .jem-build-status {
  font-size: map.get(bootstrap.$font-sizes, 2);
  line-height: 1.9;
}

.component-jem-no-data {
  .jem-no-data {
    overflow: hidden;
    position: relative;
    text-align: center;
  }
}
