.component-bi-andon-station-summary {
  width: 99%;

  tabulator-row:first-child {
    padding-top: 14px;
  }

  .tabulator-row {
    padding-bottom: 7px;
    padding-top: 7px;
  }
}
