@use 'sass:color';
@use 'sass:map';
@use 'pkg:bootstrap';

$popup-background: #333;
$popup-text: #9e9e9e;

.tree-dock {
  min-height: 1px;
  z-index: map.get(bootstrap.$zindex-levels, 'dockable');

  &.open {
    width: 24rem;
  }

  .tree-container {
    box-shadow: -1px 2px 38px rgba(bootstrap.$gray-900, 15%);
  }

  .tree-list {
    max-height: calc(100vh - 200px);
  }

  li {
    background-position: left center;
    background-repeat: no-repeat;
    font-size: 0.875rem;
    padding: 2px 0 0 20px;
    white-space: nowrap;
  }

  .tree-util-bar {
    background-color: bootstrap.$white;
  }

  .plant-task:last-of-type {
    padding-bottom: 5px;
  }

  .drag-hovered {
    background-color: rgba(bootstrap.$primary, 25%);
    border-radius: 3px;

    // needed to ensure dragenter and dragleave work
    .toggle-children,
    .tree-item-label
    {
      pointer-events: none;
    }
  }

  .task-icon {
    background-size: 20px 20px;
    height: 20px;
    margin: unset;
    margin-left: 5px;
    min-width: 30px;
    width: 30px;
    z-index: map.get(bootstrap.$zindex-levels, 'popover');
  }

  .toggle-children {
    z-index: map.get(bootstrap.$zindex-levels, 'popover');

    &:hover svg {
      color: bootstrap.$primary;
    }
  }

  .tree-station,
  .tree-station-opened,
  .tree-area,
  .tree-group {
    height: 24px;
    width: 24px;
  }

  .tree-item-label {
    cursor: pointer;
    white-space: nowrap;

    .test-tree-item-name {
      line-height: 24px;
      width: 95%;
    }

    input {
      height: 24px;
    }
  }

  .popup-content {
    background-color: $popup-background;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 30%);
    color: $popup-text;
    display: block;
    height: 45px;
    margin-top: 0;
    max-height: 700px;
    min-width: 0;
    padding: 10px;
    position: absolute;
    right: 20px;
    text-align: right;
    top: -14px;
    z-index: map.get(bootstrap.$zindex-levels, 'popover');

    svg {
      background: linear-gradient(to bottom, color.adjust($popup-background, $lightness: 10%) 0%, $popup-background 100%);
      border: 1px solid color.adjust($popup-background, $lightness: -10%);
      border-radius: 3px;
      font-size: 18px;
      margin: 0 10px 0 3px;
      padding: 3px 5px;

      &:hover {
        color: color.adjust($popup-text, $lightness: 40%);
        cursor: pointer;
        text-decoration: none;
      }
    }

    &::after {
      border-color: transparent #34302b;
      border-style: solid;
      border-width: 7px 0 7px 7px;
      content: '';
      display: block;
      left: auto;
      margin-left: -7px;
      position: absolute;
      right: -7px;
      top: 14px;
      width: 0;
      z-index: map.get(bootstrap.$zindex-levels, 'popover');
    }
  }

  .tree-menu-item {
    border-radius: 3px;
    color: inherit;
    display: block;
    padding: 1px;
    position: relative;
    text-decoration: none;

    .tree-menu-item-controls {
      display: inline-block;
      right: 5px;
      top: 3px;
    }

    &.active {
      border-radius: 3px;
      color: map.get(bootstrap.$theme-colors, 'brand');
      font-weight: 600;
    }

    .tree-lock,
    .tree-menu-item-controls,
    .popup-content {
      display: none;
    }

    &:hover {
      &:not(.active, .test-group-item, .test-task-item) {
        color: bootstrap.$primary;
      }

      .tree-lock,
      .tree-menu-item-controls {
        display: revert;
      }

      .tree-menu-item-controls:hover {
        .popup-content {
          display: revert;
        }
      }
    }
  }
}
