@use 'pkg:bootstrap';

.component-work-instruction-editor-library-card {
  .instruction-card {
    .name {
      color: bootstrap.$input-color;
      display: block;
      flex-grow: 1;
    }

    .icon-dynamic {
      color: bootstrap.$primary;
      font-size: 0.85em;
      line-height: 12px;
      margin-left: 2px;
    }
  }
}
