@use 'pkg:bootstrap';

.plant-hardware {
  .no-hover-row:hover,
  input[readonly] {
    background-color: bootstrap.$gray-100 !important;
  }

  .hardware-list {
    .col-hardware-status {
      min-width: 9rem;
    }
  }
}
