@use 'pkg:bootstrap';
@use 'variables';
@use 'sass:map';

$nested-header-height: 0.9375rem;
$btn-width-sm: 0.125rem + (bootstrap.$btn-padding-x-sm * 2) + bootstrap.$btn-font-size-sm;

.table {
  --bs-table-bg: inherit;
  --bs-table-color: inherit;
}

.bootstrap-table {
  .form-row.header-row {
    align-items: center;
    color: bootstrap.$gray-600;
    font-size: map.get(bootstrap.$font-sizes, 5);
    font-weight: bold;
    height: auto;
    line-height: 1;
    margin-bottom: 2px;
    min-height: 1.875rem;
    text-transform: uppercase;

    .subheader {
      font-size: 0.75rem;
      text-transform: capitalize;
    }

    &>div {
      align-items: center;
      padding-bottom: 5px;
    }

    &>div:not(.border-0) {
      &::after {
        border-bottom: variables.$table-border;
        content: '';
        display: block;
        height: 2px;
        margin-top: 0.5rem;
      }
    }
  }

  .nested-header {
    align-items: center;
    color: bootstrap.$gray-400;
    font-size: map.get(bootstrap.$font-sizes, 5);
    font-weight: 600;
    height: $nested-header-height;
    margin-bottom: 0.4rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  .form-row {
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;

    &:not(.header-row):hover {
      background-color: bootstrap.$gray-100;
    }

    .form-row {
      background-color: inherit;

      &:last-of-type {
        padding-bottom: 0 !important;
      }
    }

    &.selected {
      background-color: bootstrap.$light;
    }
  }

  .col-icon {
    @extend .col-auto;

    box-sizing: content-box;
    min-width: $btn-width-sm;

    &:not(:empty) {
      display: flex;
      justify-content: center;
    }

    &:empty::before {
      content: '';
      display: block;
      min-height: 0.75rem;
      visibility: hidden;
      width: $btn-width-sm;
    }
  }

  .align-nested-header {
    margin-bottom: 0;
  }
}

.grab {
  cursor: grab;

  &.grabbing,
  &:active {
    cursor: grabbing;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

.drag-handle {
  color: bootstrap.$gray-400;
  cursor: grab !important;

  &:hover {
    color: bootstrap.$gray-800;
  }
}
