@use 'pkg:bootstrap';
@use 'sass:map';

.modal-backdrop {
  backdrop-filter: blur(4px);
  z-index: map.get(bootstrap.$zindex-levels, 'modal-backdrop');
}

.modal {
  z-index: map.get(bootstrap.$zindex-levels, 'modal');
}

.standard-modal {
  .modal-content {
    min-height: calc(var(--bs-modal-width) / 1.5);

    .modal-header {
      align-items: center;
      background-color: bootstrap.$modal-footer-bg;
      border-bottom: none;
      display: flex;
    }

    .modal-footer {
      align-items: flex-end;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;

      .btn {
        font-size: map.get(bootstrap.$font-sizes, 2);
        margin-left: 0;
      }

      > :not(:first-child) {
        margin-right: 0.25rem;
      }
    }
  }
}
