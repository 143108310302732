@use 'sass:map';
@use 'pkg:bootstrap';

.portal {
  .wie-enlarged-image {
    backdrop-filter: blur(0.25rem);
    background-color: rgba(bootstrap.$gray-900, 0.5);
    z-index: 9999;
  }

  .wie-get-started-modal {
    .size-card-background {
      cursor: pointer;
      width: 100%;

      &.not-ready {
        cursor: not-allowed;
      }

      &:hover {
        @extend .border, .border-primary, .border-3;
      }

      .preview-box {
        background-color: bootstrap.$gray-300;
        max-height: calc(100% - 32px);
        max-width: calc(100% - 24px);
      }
    }

    .right-panel-wrapper {
      width: 165px;
    }
  }
}

.work-instructions-page {
  .work-instructions-workspace {
    &.library-full-screen {
      .work-instruction-wrapper {
        width: auto;

        .right-drawer.toggle,
        .work-instruction-main {
          display: none !important;
        }
      }

      .property-drawer-wrapper {
        width: 100%;

        .property-drawer {
          max-width: 100%;
          width: 100%;
        }

        .tab-container {
          width: 100%;

          .editor-tabs {
            float: right;
            margin-right: -10px;
            width: 270px;
          }

          .options-contents {
            clear: both;
            padding-top: 10px;
            width: 20rem;
          }

          .search-contents {
            float: right;
            margin-bottom: 20px;
            width: 20rem;

            button {
              padding-top: 5px;
            }
          }
        }
      }
    }

    .x-file-input {
      input {
        display: none;
      }
    }
  }

  .toggle {
    background-color: bootstrap.$gray-700;
    color: bootstrap.$white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    float: left;
    height: 100%;
    justify-content: center;
    z-index: map.get(bootstrap.$zindex-levels, 'dockable');

    &:hover {
      background-color: bootstrap.$primary;
      color: bootstrap.$white;
    }
  }

  .work-instruction-main {
    .draggable-object-target {
      height: 100%;
      width: 100%;
    }
  }

  .canvas-container {
    align-items: center;
    display: flex;
    height: 100% !important;
    justify-content: center;
    width: 100% !important;

    canvas {
      left: unset !important;
      top: unset !important;
    }
  }

  .tab-container {
    width: 20rem;

    .eflex-tab {
      a {
        padding-bottom: 0.25rem;
      }
    }
  }
}

.wie-footer-controls {
  button {
    padding: 0 20px;
  }
}

.stroke-style-options,
.stroke-style-select {
  .ember-power-select-option,
  .ember-power-select-selected-item {
    align-items: center;
    display: flex;
    height: 26px;
  }

  label.stroke-option {
    height: 3px;
    margin-right: 0.75rem;
    width: 100%;
  }

  .solid {
    border-top: 3px solid black;
  }

  .dashed {
    border-top: 3px dashed black;
  }

  .dotted {
    border-top: 3px dotted black;
  }
}

.wie-context-menu {
  cursor: context-menu;
  z-index: map.get(bootstrap.$zindex-levels, 'wie-context-menu');

  ul {
    width: 10rem;

    li {
      cursor: pointer;
      list-style-type: none;

      .pull-right {
        color: bootstrap.$gray-600;
      }

      &:hover {
        background-color: bootstrap.$primary;
        color: bootstrap.$white;

        .pull-right {
          color: bootstrap.$white;
        }
      }
    }
  }
}

.wie-info-modal:focus {
  outline: none;
}
