@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-weight: 300;
  src: url('/fonts/SourceSansPro-Light.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/SourceSansPro-LightItalic.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  src: url('/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/SourceSansPro-Italic.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  src: url('/fonts/SourceSansPro-SemiBold.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/SourceSansPro-SemiBoldItalic.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  src: url('/fonts/SourceSansPro-Bold.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/SourceSansPro-BoldItalic.ttf');
}
