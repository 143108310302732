@use 'pkg:bootstrap';
@use './variables.scss' as *;

.main-header {
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown {
    align-items: center;
    display: flex;
  }

  .dropdown-menu {
    max-height: 18rem;
    min-width: 14rem;
    overflow-y: auto;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .user-dropdown-menu {
    a {
      align-items: center;
      color: inherit;
      display: flex;
      text-decoration: none;
    }

    > .dropdown-menu {
      min-width: 14rem;
      z-index: 9999;
    }

    &.show .btn-epicor-nav {
      @extend .bg-white;

      img {
        filter: $filter-black;
      }
    }
  }

  .options-dropdown-menu {
    > .dropdown-menu {
      min-width: 18.75rem;
    }
  }
}

.btn-epicor-nav {
  @extend .d-flex, .align-items-center, .justify-content-center, .rounded-0;

  border: none;
  height: 50px;
  transition: none;
  width: 50px;

  img {
    filter: $filter-white;
  }

  &:hover {
    @extend .bg-white;

    img {
      filter: $filter-brand;
    }
  }

  &.active {
    @extend .bg-white;

    img {
      filter: $filter-black;
    }
  }
}

.profile-image {
  height: 2.375rem;
  width: 2.375rem;
}
