@use 'variables';
@use 'pkg:bootstrap';

.component-tabulator-table {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabulator-table {
    position: relative;
    width: 100%;
  }

  .tabulator {
    .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='asc'] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
      border-bottom: 6px solid bootstrap.$warning;
      border-top: none;
    }

    .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='desc'] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
      border-bottom: none;
      border-top: 6px solid bootstrap.$warning;
    }
  }

  .tabulator-tableholder {
    background-color: bootstrap.$gray-900;
    overflow-x: hidden;

    .tabulator-table {
      background-color: bootstrap.$gray-900;
      font-size: 1rem;
      width: 100%;
    }
  }

  .tabulator-header {
    .tabulator-col {
      background-color: bootstrap.$gray-600;
      color: #fff;
      font-size: 0.875rem;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .tabulator-header .tabulator-col .tabulator-col-content,
  .tabulator-cell {
    padding-left: 0.5rem;
  }

  .tabulator-row,
  .tabulator-row-odd,
  .tabulator-row-even {
    background-color: bootstrap.$gray-900;
    color: #fff;

    &:hover {
      background-color: bootstrap.$gray-900;
      color: #fff;
    }

    .tabulator-cell {
      border-right: none;
    }
  }

  .tabulator-tree-level-0:not(.has-children) {
    .tabulator-cell:first-child {
      padding-left: 28px;
    }
  }

  .tabulator-row,
  .tabulator-cell {
    min-height: 35px;
  }

  .tabulator-row.opened {
    color: bootstrap.$primary;
    font-weight: bold;

    .child-container {
      font-weight: normal;
    }
  }

  .tabulator-footer {
    background-color: bootstrap.$gray-600;
    color: #fff;

    .tabulator-page {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      margin-right: 1rem;
      outline: none;

      &:hover:not(:disabled) {
        .icon {
          color: bootstrap.$warning;
        }
      }

      &:disabled {
        opacity: variables.$disabled-opacity;
      }
    }
  }

  .page-count {
    font-size: 0.875rem;
  }

  .child-container {
    padding: 0 30px;
    width: 100%;
  }

  .tabulator-cell,
  .child-table .tabulator-cell {
    &.good {
      background-color: bootstrap.$success;
      color: bootstrap.$input-color;
    }

    &.bad {
      background-color: bootstrap.$danger;
      color: bootstrap.$input-color;
    }
  }

  .collapse-icon,
  .expand-icon {
    font-size: 1.2rem;
  }


  .child-table {
    border: 1px solid bootstrap.$dark;

    .tabulator-header {
      border-bottom: none;

      .tabulator-col.tabulator-sortable {
        background-color: bootstrap.$gray-900;
        border-right: none;

        &:hover {
          background-color: bootstrap.$gray-900;
        }
      }
    }

    .tabulator-row,
    .tabulator-cell,
    .child-container {
      background-color: bootstrap.$gray-700;

      &.good,
      &.bad {
        opacity: 0.75;
      }

      &:hover {
        background-color: bootstrap.$gray-700;
      }
    }

    .child-container {
      .child-table {
        border: 1px solid bootstrap.$dark;
      }

      .tabulator-header {
        border-bottom: none;
      }

      .tabulator-row,
      .tabulator-cell {
        background-color: bootstrap.$gray-800;
      }
    }
  }
}
