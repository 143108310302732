@use 'pkg:bootstrap';
@use 'sass:map';

.id {
  text-align: center;
  width: 75px;
}

.center {
  text-align: center;
}

.form-invalid-label {
  background-color: #e65656;
  border-radius: 3px;
  color: white;
  font-size: map.get(bootstrap.$font-sizes, 6);
  font-style: italic;
  padding: 5px;
  white-space: nowrap;

  i {
    margin-right: 3px;
  }
}

.input-disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.form-check.form-switch input {
  cursor: pointer;
}

form > * {
  margin-bottom: 0.75rem;
}
