.task-type-select .task-type-menu {
  display: inline-block;

  &.disabled {
    .tree-icon {
      opacity: 0.4;
    }
  }

  .tree-icon {
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    height: 20px;
    margin-top: -3px;
    vertical-align: middle;
    width: 20px;
  }
}
