@use 'pkg:bootstrap';
@use 'sass:map';

.side-panel {
  box-shadow: -5px 0 38px rgba(bootstrap.$gray-600, 0.25);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50vw;
  z-index: map.get(bootstrap.$zindex-levels, 'side-panel');
}

.side-panel-content {
  min-height: 0;
}

.side-panel-footer {
  min-height: 0;
  width: 100%;

  .square-button {
    @extend .btn-outline-gray-700, .bg-white;

    font-weight: bold;

    &:hover {
      @extend .bg-gray-700;
    }
  }

  .panel-buttons {
    padding-top: 2px;
  }
}

.side-panel-footer + .side-panel-content {
  border-bottom: 0;
}
