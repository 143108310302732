@use 'pkg:bootstrap';

.component-jem-task-multispindle-details {
  .bootstrap-table {
    background-color: bootstrap.$secondary;
  }

  .bootstrap-table .form-row.spindle-row {
    background-color: bootstrap.$gray-400;

    &.spindle-started {
      background-color: bootstrap.$info;
    }

    &.spindle-good {
      background-color: bootstrap.$success;
    }

    &.spindle-rejected {
      background-color: bootstrap.$danger;
    }
  }
}
