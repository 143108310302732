@use 'pkg:bootstrap';

.jem-timer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  text-align: center;
  width: 200px;
  z-index: 100;

  &.timer-white {
    .timer-color {
      @extend .text-white;
    }
  }

  &.timer-success {
    .timer-color {
      @extend .text-success;
    }
  }

  &.timer-warning {
    .timer-color {
      @extend .text-warning;
    }
  }

  &.timer-danger {
    .timer-color {
      @extend .text-danger;
    }
  }

  .actual-time-label {
    color: bootstrap.$gray-200;
    float: right;
    font-size: 24px;
    margin-top: 10px;
    text-transform: uppercase;
    width: 200px;
  }

  h3.target-time {
    color: bootstrap.$gray-200;
    float: right;
    margin-bottom: 15px;
    margin-top: 0;
    width: 200px;

    .target-timer {
      display: flex;
      flex-wrap: nowrap;
      float: right;
      justify-content: center;
      width: 200px;
    }

    .target-time-label {
      float: right;
      font-size: 24px;
      text-transform: uppercase;
      width: 200px;
    }

    .min,
    .sec {
      .time {
        color: #fff;
        font-size: 50px;
        font-stretch: condensed;
        font-weight: bootstrap.$font-weight-bold;
        margin-right: 3px;
      }

      .units {
        font-size: 20px;
      }
    }
  }
}
