@use 'sass:map';
@use 'pkg:bootstrap';

.eflex-tree-criteria {
  min-height: 1px;

  > * {
    margin-bottom: 0.75rem;
  }

  .tree-list {
    color: bootstrap.$gray-700;
    max-height: calc(100vh - 325px);
  }

  .toggle-children {
    margin: 7px 5px 0 10px;
    z-index: map.get(bootstrap.$zindex-levels, 'popover');

    &:hover {
      color: bootstrap.$dark;
      cursor: pointer;
    }
  }

  .tree-menu-item {
    border-radius: 3px;
    padding: 5px 5px 1px;

    &:hover {
      background: #a2a2a250;
    }
  }

  .area-children {
    font-weight: normal;
  }

  .group-children {
    margin-left: 16px;
  }

  .station-children {
    margin-left: 20px;
  }

  li {
    margin: 2px 0;
    padding: 2px 5px 0 10px;
  }

  .plant-area {
    font-weight: bold;
    margin: 0;
    padding-left: 0;
  }

  .plant-task:last-of-type {
    padding-bottom: 5px;
  }
}
