@use 'pkg:bootstrap';

.bom-builder-panel {
  .app-option-select {
    .ember-power-select-placeholder {
      color: bootstrap.$input-color;
      font-size: 1rem;
      line-height: 1.75;
    }
  }

  .col-type {
    max-width: 100px;
  }
}
