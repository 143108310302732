@use 'functions';
@use 'pkg:bootstrap';

$top-control-height: 80px;
$btm-control-height: 40px;
$load-btn-height: 35px;
$tab-padding: 1rem;

.component-work-instruction-editor-library {
  height: 100%;
  width: 100%;

  .library-full-screen-controls-portal {
    margin-left: 10px;
    position: relative;
    z-index: 1000;
  }

  .library-export-options {
    background-color: #545a69;
    border-radius: 0 !important;
    left: 1px !important;
    margin-bottom: 0 !important;
    width: 70px !important;

    .popover-body {
      color: #fff;
      font-size: 13px !important;
      margin: 0 !important;
      padding: 0 !important;

      .left-option {
        border-right: 1px solid #fff;
        padding: 3px 4px 3px 3px;
      }

      .right-option {
        padding: 3px 3px 3px 4px;
      }
    }

    .popover-arrow {
      display: none;
    }
  }

  .cards-selected {
    color: bootstrap.$primary;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    line-height: 1em;
    width: 100%;
  }

  .cards-selected-list {
    padding: 3px 6px 0;
    text-align: center;
  }

  .cards-selected-thumb {
    padding-right: 3px;
    padding-top: 8px;
    text-align: right;
  }

  .folder-edit-btn {
    border: none;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .title,
  .selected-folder-title {
    color: bootstrap.$primary;
    font-size: 16px;
  }

  .selected-folder-title {
    opacity: 1 !important;
    padding-left: 8px;
    padding-right: 8px;
    width: calc(100% - 12px);

    &:not(:focus) {
      background: rgba(0, 0, 0, 0%);
      border: none;
    }
  }

  .empty-notice {
    color: bootstrap.$gray-600;
    font-size: 14px;
    font-weight: bold;
    padding-left: 9px;
  }

  .library-card {
    padding-bottom: 2px;

    .card-background {
      cursor: pointer;
      min-height: 145px;
      padding: 9px;

      &:hover,
      &.hovered {
        background-color: rgba(0, 0, 0, 3%);
      }

      &.card-row {
        height: auto;
        padding: 0;
      }
    }

    .thumb,
    .large-thumb {
      line-height: 80px;
      margin-bottom: 4px;
      max-width: 100%;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;

      img {
        max-height: 100%;
        max-width: 100%;
      }

      .approval-status.icon {
        position: absolute;
        right: 4px;
        top: 4px;

        i.icon-wie-approve-reject-pending {
          height: 28px;
          width: 28px;
        }

        i.icon-wie-approved,
        i.icon-wie-rejected {
          height: 16px;
          width: 16px;
        }

        i.icon-wie-rejected {
          margin-top: 3px;
        }

        i.icon-wie-approved-note,
        i.icon-wie-rejected-note {
          height: 22px;
          width: 22px;
        }
      }
    }

    .thumb {
      height: 80px;
    }

    .large-thumb {
      height: 180px;
    }

    .name {
      color: bootstrap.$gray-600;
      float: left;
      font-size: 13px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .stations {
      color: bootstrap.$primary;
      float: right;
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      text-align: right;
      width: 20%;
    }

    .updated {
      font-size: 11px;
      letter-spacing: -0.85px;
    }

    .info {
      color: #9e9d9c;
      font-size: 12px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .library-card.small-thumb:nth-of-type(odd) {
    padding-left: 0;
    padding-right: 5px;
  }

  .library-card.small-thumb:nth-of-type(even) {
    padding-left: 0;
    padding-right: 5px;
  }

  .library-card.active {
    .card-background {
      background-color: #e1e1e1;
    }
  }

  .library-card.selected {
    background-color: initial;

    .thumb {
      img,
      .icon-wie-folder {
        border: 2px solid bootstrap.$primary;
      }
    }

    .name {
      color: bootstrap.$primary;
    }

    .info {
      color: bootstrap.$gray-600;
      font-weight: bold;
    }
  }

  .library-top-controls {
    height: $top-control-height;

    .info-icon {
      background-image: functions.image-url('wizard-info.svg');
      background-repeat: no-repeat;
      cursor: pointer;
      display: none;
      height: 16px;
      margin: 0;
      opacity: 0.75;
      width: 16px;
    }

    .btn-list-view,
    .btn-thumb-view {
      color: #ababab;
    }

    .search-contents {
      position: relative;

      input.library-search {
        display: block;
        margin: 4px auto 0;
        padding-right: 20px;
        position: relative;
        width: 100%;
      }

      button.search {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      button.clear.search {
        font-size: 20px;
      }
    }
  }

  .library-contents {
    position: relative;

    .folder-details {
      .icon-wie-folder {
        height: 24px;
        margin: 3px;
        min-height: auto;
        width: 24px;
      }
    }

    .wie-spinner-overlay {
      background-color: black;
      height: 100%;
      opacity: 0.5;
      position: absolute;
      width: 100%;
      z-index: 1000;

      .spinner {
        z-index: 1010 !important;
      }
    }

    .scroll-container {
      height: 100%;
      position: relative;
    }

    .library-contents-inner {
      overflow-y: auto;
      padding-left: 6px;

      &.list-view {
        padding-bottom: 0;
      }
    }
  }

  .library-contents.no-btm-controls {
    height: calc(100% - #{$top-control-height + $load-btn-height});
  }

  .library-contents.no-load-button {
    height: calc(100% - #{$top-control-height + $btm-control-height - 10px});
  }

  .library-contents:not(.list-contents) {
    display: flex;
    flex-direction: column;
  }

  .library-contents.list-contents {
    height: calc(100% - #{$top-control-height + $btm-control-height + $load-btn-height - 40px});
    width: 100%;
  }

  .load-btn {
    height: $load-btn-height;
  }

  .load-btn-normal {
    border-radius: 4px;
    margin-bottom: 15px;
    margin-left: 10px;
    width: 231px;
    z-index: 10;
  }

  .load-btn-modal {
    width: 270px;
    z-index: 9999;
  }

  .library-btm-controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    z-index: 10;

    span.x-file-input {
      > input {
        display: none;
      }

      // This nonsense has to be here because x-file-input makes a bunch of bad assumptions in it's styling.
      > label {
        cursor: default;
        height: 100%;

        &:hover {
          background-color: #e1e1e1;
          cursor: pointer;
        }
      }

      /* stylelint-disable-next-line selector-class-pattern */
      &.x-file-input--disabled {
        > label:hover {
          background: none;
          cursor: default !important;
        }
      }
    }

    &.library-btm-control-list {
      margin-left: 20px;
      margin-top: calc(6px + #{$tab-padding});
      width: calc(100% - 10px);

      .cards-selected {
        width: 85px;
      }
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .selection-options {
    height: 70px;
    padding: 0;
    position: absolute;
    width: calc(100% - 400px);

    .selected-card-image {
      max-height: 100%;
    }

    .folder-image {
      margin: unset;
      width: 70px;
    }
  }

  .options-contents {
    i {
      color: #ababab;
      font-size: 21px;
      padding: 0;
      position: absolute;

      &.fa {
        bottom: 2px;
        height: 18.25px;
        right: 2px;
        width: 20px;
      }

      &.lg-square {
        background-color: #ababab;
        bottom: 2px;
        height: 18.25px;
        right: 2px;
        width: 20px;
      }

      &.sm-square {
        background-color: #ababab;
        bottom: 1px;
        height: 13px;
        right: 1px;
        width: 13px;
      }
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      margin-left: 4px;
      outline: none;
      padding: 0;
      position: relative;

      &.selected {
        border: 1px solid bootstrap.$primary;
      }

      &.lg {
        height: 24px;
        width: 26px;
      }

      &.sm {
        height: 17px;
        margin-top: 6px;
        width: 17px;
      }
    }
  }

  .library-list-container {
    display: block;
    height: calc(100% - #{$top-control-height + $btm-control-height + $load-btn-height + 90px});
  }

  .list-view,
  .approval-view {
    background: #fbfbfb;
    border: 2px solid #ababab;
    clear: both;
    height: calc(100% - #{$top-control-height + $btm-control-height + $load-btn-height - 142px});
    margin-left: 10px;
    margin-right: 20px;
    overflow: auto;

    &.extra-space {
      height: calc(100% - 39px);
    }
  }

  .wie-library-table {
    color: #5c5c5c;
    margin-bottom: 0;
    margin-top: -1px;
    width: 100%;

    &.column-8 {
      .wie-library-table-head,
      .wie-library-table-body .wie-library-row {
        grid-template-columns: 34px minmax(auto, 110px) 226px 54px repeat(2, 1fr) 140px 120px;
      }
    }

    &.column-9 {
      .wie-library-table-head,
      .wie-library-table-body .wie-library-row {
        grid-template-columns: 34px minmax(auto, 110px) 226px 54px 1fr 2fr 1fr 140px 120px;
      }
    }

    .library-checkbox-single {
      pointer-events: none;
    }

    .wie-library-table-head {
      display: grid;
      font-weight: bold;
      height: 36px;
      left: 0;
      margin-bottom: 8px;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 10;

      .wie-library-head-cell {
        background: #e5e5e5;
        border-bottom: 1px solid #ababab;
        line-height: 24px;
        padding: 8px;
        text-transform: uppercase;
      }
    }

    @media (max-width: 1105px) {
      .wie-library-table-head {
        height: 58px;
      }
    }

    .wie-library-table-body {
      background: #fbfbfb;

      .library-item {
        cursor: pointer;

        &.not-selectable {
          cursor: default;
        }
      }

      .wie-library-row {
        display: grid;

        .wie-library-cell {
          overflow: hidden;
          padding: 8px;
          text-overflow: ellipsis;

          &.wie-image {
            text-align: center;

            img {
              max-height: 90px;
              max-width: 100%;
            }
          }
        }
      }
    }

    .wie-library-table-foot {
      border-top: 0;
      padding: 0;
      text-align: center;

      button {
        margin-top: 20px;
        padding-top: 4px;
      }
    }
  }

  .component-drag-scroll-container {
    height: 90px;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 100;

    &.is-active {
      pointer-events: auto;
    }

    &.at-top {
      top: 60px;
    }

    &.at-bottom {
      bottom: 0;
    }
  }
}
