@use 'pkg:bootstrap';
@use 'sass:map';

.component-genealogy-diagram {
  position: relative;

  .cytoscape-graph {
    border-radius: 4px;
  }

  .graph-legend {
    background-color: bootstrap.$light;
    border-radius: 4px;
    height: fit-content;
    position: absolute;
    right: 30px;
    top: 15px;
    width: fit-content;
    z-index: map.get(bootstrap.$zindex-levels, 'side-panel');

    .legend-row {
      align-items: center;
      display: flex;
      font-size: 0.85rem;
      justify-content: flex-end;
      margin: 10px 14px;

      &.total {
        justify-content: center;
      }

      .item {
        background-color: #ccc;
        border-radius: 50%;
        height: 30px;
        margin-left: 10px;
        width: 30px;

        &.depth-0 {
          background-color: #77778c;
        }

        &.depth-1 {
          background-color: #daf1fd;
        }

        &.depth-2 {
          background-color: #8fd6f6;
        }

        &.depth-3 {
          background-color: #56a3d9;
        }

        &.depth-4 {
          background-color: #426070;
        }

        &.depth-5 {
          background-color: #000;
        }

        &.end {
          background-color: #fff;
          border: 2px solid #666;
          border-radius: 0;
        }
      }
    }
  }

  .target-popper {
    background: #fff;
    border-radius: 0;
    padding: 1rem;
    text-align: left;
    z-index: map.get(bootstrap.$zindex-levels, 'above-task-cards');

    &.active {
      display: block;
    }

    .popper-title {
      font-weight: bold;
    }

    .popper-value {
      padding-left: 14px;
    }
  }

  .cy-panzoom {
    box-sizing: content-box;
    color: #666;
    font-family: arial, helvetica, sans-serif;
    font-size: 11px;
    left: 10px;
    line-height: 1;
    position: absolute;
    top: 8px;
    z-index: map.get(bootstrap.$zindex-levels, 'above-task-cards');
  }

  .cy-panzoom-zoom-button {
    background: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    box-sizing: content-box;
    cursor: pointer;
    height: 10px;
    left: 16px;
    margin-left: -1px;
    margin-top: -1px;
    padding: 3px;
    position: absolute;
    text-align: center;
    width: 10px;
    z-index: map.get(bootstrap.$zindex-levels, 'above-task-cards');
  }

  .cy-panzoom-zoom-button:active,
  .cy-panzoom-slider-handle:active,
  .cy-panzoom-slider-handle.active {
    background: #ddd;
    box-sizing: content-box;
  }

  .cy-panzoom-pan-button {
    box-sizing: content-box;
    height: 0;
    position: absolute;
    width: 0;
    z-index: map.get(bootstrap.$zindex-levels, 'above-task-cards');
  }

  .cy-panzoom-reset {
    box-sizing: content-box;
    top: 55px;
  }

  .cy-panzoom-zoom-in {
    box-sizing: content-box;
    top: 80px;
  }

  .cy-panzoom-zoom-out {
    box-sizing: content-box;
    top: 197px;
  }

  .cy-panzoom-pan-up {
    border-bottom: 5px solid #666;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    box-sizing: content-box;
    left: 50%;
    margin-left: -5px;
    top: 0;
  }

  .cy-panzoom-pan-down {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #666;
    bottom: 0;
    box-sizing: content-box;
    left: 50%;
    margin-left: -5px;
  }

  .cy-panzoom-pan-left {
    border-bottom: 5px solid transparent;
    border-right: 5px solid #666;
    border-top: 5px solid transparent;
    box-sizing: content-box;
    left: 0;
    margin-top: -5px;
    top: 50%;
  }

  .cy-panzoom-pan-right {
    border-bottom: 5px solid transparent;
    border-left: 5px solid #666;
    border-top: 5px solid transparent;
    box-sizing: content-box;
    margin-top: -5px;
    right: 0;
    top: 50%;
  }

  .cy-panzoom-pan-indicator {
    background: #000;
    border-radius: 8px;
    box-sizing: content-box;
    display: none;
    height: 8px;
    left: 0;
    margin-left: -5px;
    margin-top: -5px;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 8px;
    z-index: map.get(bootstrap.$zindex-levels, 'title-bar');
  }

  .cy-panzoom-slider {
    box-sizing: content-box;
    height: 100px;
    left: 17px;
    position: absolute;
    top: 97px;
    width: 15px;
  }

  .cy-panzoom-slider-background {
    background: #fff;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    box-sizing: content-box;
    height: 100px;
    left: 5px;
    position: absolute;
    top: 0;
    width: 2px;
  }

  .cy-panzoom-slider-handle {
    background: #fff;
    border: 1px solid #999;
    border-radius: 2px;
    box-sizing: content-box;
    cursor: default;
    height: 8px;
    line-height: 8px;
    margin-left: -2px;
    position: absolute;
    width: 16px;
    z-index: map.get(bootstrap.$zindex-levels, 'title-bar');

    .icon {
      box-sizing: content-box;
      line-height: 10px;
      margin: 0 3px;
    }
  }

  .cy-panzoom-no-zoom-tick {
    background: #666;
    border: 1px solid #fff;
    border-radius: 2px;
    box-sizing: content-box;
    height: 2px;
    left: 3px;
    margin-left: -1px;
    margin-top: 3px;
    position: absolute;
    width: 8px;
    z-index: map.get(bootstrap.$zindex-levels, 'above-task-cards');
  }

  .cy-panzoom-panner {
    background: #fff;
    border: 1px solid #999;
    border-radius: 40px;
    box-sizing: content-box;
    height: 40px;
    left: 5px;
    margin-left: -1px;
    position: absolute;
    top: 5px;
    width: 40px;
  }

  .cy-panzoom-panner-handle {
    box-sizing: content-box;
    height: 40px;
    left: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 40px;
    z-index: map.get(bootstrap.$zindex-levels, 'title-bar');
  }

  .cy-panzoom-zoom-only {
    .cy-panzoom-panner {
      display: none;
    }

    .cy-panzoom-reset {
      top: 20px;
    }

    .cy-panzoom-zoom-in {
      top: 45px;
    }

    .cy-panzoom-zoom-out {
      top: 70px;
    }
  }
}
