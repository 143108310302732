@use 'pkg:bootstrap';

.component-work-instruction-editor-tool-properties-normal {
  .background-container {
    align-items: center;
    margin-top: 8px;

    label {
      margin-right: 6px;
    }
  }

  .info-box {
    background: white;
    border: 1px solid bootstrap.$gray-600;
    border-radius: 0.25rem;
    max-height: 150px;
    min-height: 30px;
    overflow-y: auto;
    padding: 4px 6px;
    width: 100%;
  }

  .assigned-locations {
    .location {
      .location-icon {
        display: inline-block;
        height: 16px;
        margin-bottom: -2px;
        width: 16px;
      }

      .location-link {
        color: bootstrap.$primary;
        font-size: 0.85rem;
        text-decoration: underline;
      }
    }
  }

  .notes {
    color: bootstrap.$dark;
    height: 150px;
  }

  .opacity-input-wrapper {
    width: 98px;
  }
}
