@use 'sass:map';
@use 'pkg:bootstrap';

.eflex-area-tasks {
  color: bootstrap.$gray-600;
  font-size: map.get(bootstrap.$font-sizes, 5);
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  td {
    @extend .bg-transparent, .border-bottom, .border-end, .border-1, .border-gray-200;

    &::after {
      @extend .border-0;
    }
  }
}
