@use 'sass:map';
@use 'sass:color';
@use 'mixins';
@use 'pkg:bootstrap';
@use 'pkg:ember-power-select/scss/bootstrap-complete' with (
  $ember-power-select-text-color: bootstrap.$input-color,
  $ember-power-select-border-color: bootstrap.$input-border-color,
  $ember-power-select-highlighted-background: color.adjust(bootstrap.$white, $lightness: -3%),
  $ember-power-select-selected-background: color.adjust(bootstrap.$white, $lightness: -3%),
  $ember-power-select-multiple-option-border: none,
  $ember-power-select-multiple-selection-background-color: transparent,
  $ember-power-select-focus-border-color: bootstrap.$primary,
  $ember-power-select-focus-box-shadow: 0 0 3px bootstrap.$primary,
  $ember-power-select-dropdown-box-shadow: 2px 2px 12px 0 rgba(97, 104, 104, 0.25),
  $ember-power-select-placeholder-color: bootstrap.$input-placeholder-color,
);

.ember-power-select-trigger {
  border-radius: bootstrap.$input-border-radius;
  font-size: bootstrap.$input-font-size;
  line-height: bootstrap.$input-line-height;
  max-height: calc(bootstrap.$input-height * 2.5);
  min-height: bootstrap.$input-height;
  padding: bootstrap.$input-padding-y bootstrap.$input-padding-x;
  white-space: nowrap;

  &.form-control-sm {
    font-size: bootstrap.$input-font-size-sm;
    max-height: bootstrap.$input-height-sm;
    min-height: bootstrap.$input-height-sm;
    padding: bootstrap.$input-padding-y-sm bootstrap.$input-padding-x-sm;
  }

  &.form-control-lg {
    align-items: center;
    display: flex;
    font-size: bootstrap.$input-font-size-lg;
    max-height: bootstrap.$input-height-lg;
    min-height: bootstrap.$input-height-lg;
    padding: bootstrap.$input-btn-padding-y-lg bootstrap.$input-btn-padding-x-lg;
  }

  .ember-power-select-selected-item {
    margin-left: 0;
  }
}

.ember-power-select-placeholder {
  margin-left: 0;
  width: 100%;
}

.ember-power-select-selected-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ember-power-select {
  &.sm {
    .ember-power-select-trigger {
      align-items: center;
      display: flex;
      font-size: bootstrap.$input-font-size-sm;
      max-height: bootstrap.$input-height-sm;
      min-height: bootstrap.$input-height-sm;
      padding: bootstrap.$input-btn-padding-y-sm bootstrap.$input-btn-padding-x-sm;
    }
  }
}

.ember-power-select-multiple-option {
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.ember-power-select-multiple-remove-btn {
  margin-left: 5px;
  order: 6;
}

.ember-power-select-trigger[aria-disabled='true'] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ember-basic-dropdown-content {
  background-color: bootstrap.$white;
  z-index: map.get(bootstrap.$zindex-levels, 'select-options');
}

/* stylelint-disable-next-line selector-class-pattern */
.ember-power-select-dropdown.ember-basic-dropdown-content--below {
  transform: translateY(-1px);
}

.ember-power-select.standard-select {
  min-width: 80px;
  vertical-align: middle;
  width: auto;

  .ember-power-select-trigger {
    display: flex;

    .ember-power-select-placeholder {
      display: inline;
    }

    span {
      align-self: center;
      vertical-align: middle;
    }
  }
}

.no-chrome-power-select.ember-power-select-dropdown {
  box-shadow: -5px 0 38px 0 rgba(bootstrap.$gray-900, 0.25);
  height: 80%;
  min-width: 300px;

  .ember-power-select-options[role='listbox'] {
    height: calc(100% - 34px);
    max-height: calc(100% - 34px);
  }

  .ember-power-select-options {
    color: bootstrap.$input-color;
    /* stylelint-disable-next-line at-rule-empty-line-before */
    /* stylelint-disable-next-line order/order */
    @include mixins.responsive-text(20px);

    .ember-power-select-group-name {
      font-weight: bold;
    }

    .ember-power-select-option {
      &:hover {
        background-color: bootstrap.$gray-100;
      }
    }

    .ember-power-select-option[aria-selected='true'] {
      background-color: bootstrap.$info;
    }

    .ember-power-select-option[aria-current='true'] {
      color: bootstrap.$input-color;
    }
  }
}

.is-invalid {
  .ember-power-select-trigger {
    border: 1px solid #d81422;
  }

  .ember-power-select-search input {
    border: initial !important;
  }
}

.button-power-select {
  margin-right: 10px;
  margin-top: 5px;
  width: fit-content !important;
}

.ember-model-select-dark {
  background-color: transparent;
  border: 1px solid bootstrap.$gray-600;

  &:focus {
    background-color: transparent;
  }

}
