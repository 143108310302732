@use 'pkg:bootstrap';

$bracket-gap: 20px;

.complex-option-builder {
  .query-builder {
    .rule-container,
    .rule-placeholder {
      background-color: #0000;
      border-color: rgba(bootstrap.$gray-900, 0.25);
      border-radius: 3px;
      margin: 6px 0;
      padding: 0.5rem;
    }

    .rules-group-container {
      background-color: bootstrap.$white;
      border: none;
      padding: 0;
      width: 100%;

      .rules-group-container {
        background-color: rgba(bootstrap.$gray-900, 0.08);
        border: none;
        border-width: 1px;
        margin: 6px 1px;
        padding: 1rem;
      }
    }

    .rule-filter-container,
    .rule-operator-container,
    .rule-value-container,
    .rule-actions {
      select,
      .btn-danger {
        padding-bottom: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.25rem;
      }
    }

    .rule-filter-container,
    .rule-value-container,
    .rule-operator-container {
      select {
        padding-right: 2rem;
      }
    }

    .rule-value-container {
      margin-left: 6px;
      padding-left: 9px;
    }

    .rules-list {
      padding: 0 0 0 $bracket-gap + 5px;
    }

    .rules-list > ::after,
    .rules-list > ::before {
      border-color: bootstrap.$gray-600;
      left: -1 * $bracket-gap;
      width: $bracket-gap;
    }

    .rule-actions {
      float: right;
    }

    .group-actions {
      .btn-success {
        @extend .btn-primary, .fw-semibold;
      }

      .btn {
        border-radius: 3px;
        margin-right: 0.5rem;
        text-transform: capitalize;
      }
    }

    .group-conditions {
      .btn-primary {
        @extend .btn-outline-gray-700;


        &:not(.active) {
          background-color: bootstrap.$white;

          &:hover {
            background-color: bootstrap.$gray-200;
            color: bootstrap.$gray-700;
          }
        }
      }
    }
  }
}
