@use '../functions';

.evision-image-retrieval {
  .image-location-col {
    max-width: 300px;
    text-align: left;
    word-wrap: break-word;
  }

  .thumbnail-icon {
    background-image: functions.image-url('thumbnailicon.svg');
    height: 25px;
    margin: auto;
    width: 25px;
  }

  i.pass-mark {
    color: #56a148;
  }

  i.fail-mark {
    color: #d9534f;
  }
}
