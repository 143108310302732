.eflex-station-tasks,
.eflex-operation-tasks {
  .task-grid-wrapper {
    flex: 1;
    left: -6px;
    position: relative;
  }

  .task-parent-actions-dropdown {
    &::after {
      display: none;
    }
  }
}
