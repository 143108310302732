@use 'pkg:bootstrap';

$nav-item-padding: 0.2rem;

.nav-bar {
  > nav {
    width: 12.5rem;
  }

  .menu-logo {
    margin-left: 1.75rem;
  }

  a {
    color: bootstrap.$gray-900;
    display: block;
    font-weight: 500;
    padding: $nav-item-padding 0 $nav-item-padding $nav-item-padding;
    white-space: nowrap;

    &.active {
      color: bootstrap.$black;
      font-weight: 600;
      outline: 0;
      pointer-events: none;
      text-decoration: none;
    }

    &:hover {
      color: bootstrap.$primary;
      outline: 0;
      text-decoration: none;
    }
  }

  li {
    &.expanded {
      .sub-menu-arrow {
        transform: rotate(90deg);
      }
    }
  }

  .nav-children {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

    .active {
      color: bootstrap.$black;
      font-weight: 600;
    }

    a {
      background: bootstrap.$white;
      font-weight: 500;
      margin-left: 1.75rem;
      padding: $nav-item-padding;
    }

    a:hover {
      color: bootstrap.$primary;
    }
  }

  .nav-item-icon {
    display: inline-block;
    max-width: 30px;
    text-align: center;
    width: 24px;

    i,
    img {
      margin: auto;
      padding: 0;
    }

    img {
      max-width: 16px;
      width: 100%;
    }
  }
}

.nav-bar:not(.collapsed) {
  .nav-item-icon {
    @extend .me-1;
  }

  .sub-menu-arrow-wrapper {
    @extend .ms-auto;
  }
}

// When left nav menu is collapsed make sure that nav links text is not overlapped with content
.nav-bar.collapsed {
  > nav {
    width: 3.65rem;
  }

  .has-children {
    @extend .py-1;
  }

  .nav-children {
    @extend .shadow;

    a {
      margin: 0 !important;
      padding: $nav-item-padding;
    }
  }

  a {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 2rem;
    justify-content: center;
    padding: 0.316rem 0;
  }

  li {
    &.expanded {
      > a {
        @extend .rounded;

        background: bootstrap.$gray-200;
      }
    }

    &:not(.expanded) {
      a {
        margin-bottom: 0.5rem;

        &:hover {
          background: bootstrap.$gray-100;
        }
      }

      .sub-menu-arrow {
        transform: rotate(-90deg);
      }
    }
  }

  .sub-menu-arrow {
    @extend .mx-auto;
  }
}
