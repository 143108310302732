@use 'pkg:bootstrap';

.task-panel {
  .task-panel-tabs {
    flex: 1 1 auto;
    min-height: 0;

    .task-tabs-wrapper {
      z-index: 10;
    }
  }

  .task-panes-wrapper {
    @extend .row, .flex-nowrap;

    height: 100%;
    width: auto;

    > div {
      @extend .col-auto;
    }
  }

  #pane-task-wie {
    overflow-y: auto;
  }

  .task-type-select {
    .ember-power-select-options {
      max-height: 550px;
    }
  }

  .context-col-enable {
    width: 75px;
  }

  .context-specific-name {
    font-size: 0.875rem;
  }
}
