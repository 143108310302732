@use 'pkg:bootstrap';
@use 'sass:math';
@use 'sass:map';

.bi-page {
  background-color: bootstrap.$gray-900;
  height: 100%;

  .toggle {
    background-color: bootstrap.$gray-700;
    color: bootstrap.$white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      background-color: bootstrap.$primary;
    }
  }

  .criteria-selection {
    background-color: bootstrap.$white;
    min-width: 550px;
    overflow: auto;
  }

  .station-quality-p-chart {
    height: 90%;
  }

  .subgroup-size-wrapper {
    height: 10%;
  }

  .bi-no-data {
    color: bootstrap.$primary;
    font-size: 2rem;
  }

  .component-bi-dropdown {
    .ember-power-select-trigger {
      background-color: transparent;
      border-color: #fff;
      box-shadow: none;
      color: #fff;
      cursor: pointer;
      position: relative;
      width: 125px;
      z-index: map.get(bootstrap.$zindex-levels, 'select-options');
    }
  }

  .component-bi-chart-with-dropdown {
    .chart-row {
      position: relative;
      top: -30px;
    }
  }

  .component-bi-oee-metrics-table {
    .tabulator-col {
      font-size: map.get(bootstrap.$font-sizes, 5) !important;
    }
  }
}
