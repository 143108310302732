@use 'pkg:bootstrap';

.component-jem-instructions-animation {
  .header-row {
    * {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .play-col {
    text-align: right;
    width: 18px;

    .icon-play {
      color: bootstrap.$gray-400;
      cursor: pointer;
    }
  }

  .trash-col {
    text-align: right;
    width: 18px;

    .trash-can {
      display: inline-block;
      vertical-align: baseline;
    }
  }

  .color-col {
    text-align: center;
  }
}
