.component-jem-bolt {
  .tightening-col {
    align-self: center;
  }

  &.is-stale {
    .detail-text-value fw-bold text-gray-900,
    .bolt-error {
      opacity: 0.6;
    }
  }
}
