.component-jem-task-error-proofing-details {
  .icon {
    font-size: 1.5rem;
  }

  .error-proofing-input {
    .col-state {
      width: 100px;
    }

    .input-name {
      margin-bottom: 1px;
    }
  }

  .expected-value {
    font-style: normal !important;
  }
}
