@use 'sass:color';
@use 'pkg:bootstrap';

.main-overflow {
  overflow-x: hidden;
  overflow-y: auto;
}

.overflow-overlay {
  overflow: overlay;
}

.sticky-bottom {
  bottom: 0;
  position: sticky;
  z-index: bootstrap.$zindex-sticky;
}

/* image can be max-width and max-height at the same time for responive container */
.img-responsive {
  height: 100%;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.inline {
  label {
    display: inline;
    margin-left: 15px;
    margin-right: 5px;
  }

  li {
    display: inline;
    margin: 0 10px 0 0;
  }
}

.good {
  background-color: bootstrap.$success;

  &:hover {
    background-color: color.adjust(bootstrap.$success, $lightness: -5%);
  }
}

.invalid {
  background-color: bootstrap.$warning;

  &:hover {
    background-color: color.adjust(bootstrap.$warning, $lightness: -5%);
  }
}

.bad {
  background-color: bootstrap.$danger;
  color: white;

  &:hover {
    background-color: color.adjust(bootstrap.$danger, $lightness: -5%);
  }
}

.expired {
  background-color: bootstrap.$gray-200;
  color: white;

  &:hover {
    background-color: color.adjust(bootstrap.$gray-200, $lightness: -5%);
  }
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.icon-link {
  cursor: pointer;
}

.light-text {
  color: bootstrap.$gray-400;
}

.condensed {
  font-stretch: condensed;
}

.square-color-picker {
  cursor: pointer;
  height: 2rem;
  max-width: 3rem;
  min-width: 3rem;
  padding: .25rem;
}

.preserve-spaces {
  white-space: pre-wrap;
}

.allow-pointer-events {
  pointer-events: all;
}

.opacity-0 {
  opacity: 0;
}

.btn.btn-peripheral-dark {
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
  --bs-btn-color: #{bootstrap.$gray-300};
  --bs-btn-hover-color: #{bootstrap.$white};
  --bs-btn-active-color: #{bootstrap.$white};
  --bs-btn-active-border-color: transparent;
}

.btn.btn-peripheral {
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
  --bs-btn-color: #{bootstrap.$gray-500};
  --bs-btn-hover-color: #{bootstrap.$gray-800};
  --bs-btn-active-color: #{bootstrap.$gray-900};
  --bs-btn-active-border-color: transparent;
}

.rounded-circle {
  &.btn {
    --bs-btn-line-height: 1;
    --bs-btn-padding-y: 0.625rem;
    --bs-btn-padding-x: 0.625rem;

    min-height: 38px;
    min-width: 38px;
  }

  &.btn-sm {
    --bs-btn-line-height: .875;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 0.5rem;

    min-height: 32px;
    min-width: 32px;
  }
}

@for $i from 1 through 10 {
  .width-rem-#{$i} {
    width: #{$i}rem;
  }

  .height-rem-#{$i} {
    height: #{$i}rem;
  }
}

.background-gray-100 {
  background-color: bootstrap.$gray-100;
}

.width-fit-content {
  min-width: fit-content;
  width: fit-content;
}

/* stylelint-disable-next-line selector-class-pattern */
.svg-inline--fa {
  --fa-fw-width: 1em;
}
