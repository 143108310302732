.eflex-status-servers {
  margin-top: 15px;

  .links {
    .link {
      display: block;
      position: relative;

      a {
        display: inline-block;
      }

      i {
        display: none;
        position: absolute;
        top: 0;
      }
    }

    .link:hover {
      i {
        display: inline-block;
        padding-left: 30px;
      }
    }

    .add-link {
      i {
        padding-left: 10px;
      }
    }
  }

  .network-table {
    th {
      text-align: center;
    }
  }
}
