@use 'sass:color';
@use 'sass:map';
@use 'pkg:bootstrap';
@use 'variables';

// Temporary
.square-button:disabled {
  cursor: not-allowed;
  pointer-events: all;
}

.btn-secondary {
  @extend .border-gray-300, .border-1;
}

.btn-secondary-dark {
  @extend .border-gray-600, .border-1;
}

// workaround https://github.com/emberjs/ember.js/issues/19285
button[disabled] > * { pointer-events: none; }

// Inputs
input,
textarea {
  &:disabled {
    cursor: not-allowed;
    opacity: variables.$disabled-opacity !important;

    &[readonly] {
      opacity: 1 !important;
    }
  }
}

// Buttons
.btn-group {
  > label:focus {
    box-shadow: none !important;
  }

  &.radio-btn-group {
    .btn-check:checked + label {
      background-color: bootstrap.$primary;
      border-color: bootstrap.$primary;
      color: bootstrap.$white;
    }
  }
}

a.btn.disabled {
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  color: var(--bs-btn-disabled-color);
  opacity: var(--bs-btn-disabled-opacity);
  pointer-events: none;
}

// Forms
.form-control.is-valid:focus {
  &#{&} {
    border-color: bootstrap.$primary;
    box-shadow: bootstrap.$input-focus-box-shadow;
  }
}

.is-required > label::after {
  color: bootstrap.$danger;
  content: '*';
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: color.adjust(bootstrap.$white, $lightness: 4%);
}

.popover {
  max-width: none;
}

*:focus {
  outline-color: bootstrap.$primary !important;
}

input[readonly] {
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

strong {
  color: bootstrap.$gray-700;
}

fieldset {
  @extend .border, .border-gray-300, .rounded;

  height: calc(100% - 1rem);
  margin-top: 1rem;
  padding: 1rem;
  padding-bottom: 1rem;
  padding-top: 1.75rem;
  position: relative;
}

legend {
  @extend .px-2;

  background-color: bootstrap.$white;
  font-weight: bootstrap.$font-weight-semibold;
  left: 0.5rem;
  position: absolute;
  top: -0.875rem;
  width: auto;
}

select {
  border: 1px solid color.adjust(bootstrap.$border-color, $lightness: -10%);
  border-radius: 3px;
  color: black;
  padding: 5px 2px 4px;
}

label.radio {
  display: block;
  line-height: 30px;
}

.form-text {
  color: color.adjust(bootstrap.$border-color, $lightness: -25%);
  font-size: map.get(bootstrap.$font-sizes, 3);
  font-style: italic;
}

.form-label.form-check-label {
  font-size: var(--bs-body-font-size);
  font-weight: normal;
  margin-bottom: 0;
}

textarea.form-control {
  height: auto;
}

.offcanvas-top {
  --bs-offcanvas-height: fit-content;
}

.invalid-tooltip {
  z-index: map.get(bootstrap.$zindex-levels, 'top-menu');
}

.is-invalid + .invalid-tooltip,
.is-invalid + label + .invalid-tooltip {
  display: none;
}

*:hover + .invalid-tooltip,
.is-invalid:hover + label + .invalid-tooltip {
  display: block !important;
}

*:has(+ .invalid-tooltip),
*:has(+ .invalid-tooltip) div {
  border-color: var(--bs-form-invalid-border-color) !important;
}

.form-check-input.is-valid {
  --bs-form-valid-color: #{bootstrap.$form-check-input-checked-bg-color};
  --bs-form-valid-border-color: #{bootstrap.$form-check-input-checked-border-color};
}

.form-control.is-valid {
  padding-right: bootstrap.$input-btn-padding-x;
}

.dropdown-menu-dark {
  box-shadow: bootstrap.$dropdown-dark-box-shadow;
}

.bg-brand-texture {
  background: url('/images/geometric-dark.png');
  background-position: center center;
  background-size: cover;
}
