@use 'pkg:bootstrap';

.component-jem-stv {
  .text-gray-900 {
    @extend .text-white;
  }

  .text-gray-800 {
    @extend .text-gray-200;
  }

  .description .eflex-icon {
    height: 1.375rem;
    width: 1.375rem;
  }

  .status {
    &.success {
      color: bootstrap.$success;
    }

    &.danger {
      color: bootstrap.$danger;
    }

    &.info {
      color: bootstrap.$info;
    }
  }

  .inline-error {
    color: bootstrap.$danger;
    font-size: 14px;
    margin-top: -18px;

    i {
      margin-right: 8px;
    }
  }

  label {
    font-size: 12px;
  }

  button {
    font-size: 1.5rem;
    font-weight: bold;
    height: 100%;
    max-height: 5rem;
    min-height: 2rem;
    width: 100%;
  }

  .jem-process-data-inputs {
    .eflex-radio-button label {
      color: #8b8f94;
    }

    .eflex-radio-button :checked + label {
      color: #fff;
    }
  }

  .component-option {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .jem-task-table .form-row {
    padding-bottom: 5px;
    padding-top: 5px;

    &.header-row {
      flex-wrap: nowrap;
      font-size: 0.75rem;
    }

    .detail-text-value fw-bold text-gray-900 {
      flex-direction: column;
    }

    .detail-text-value fw-bold text-gray-900 div {
      font-size: 1.25rem;
      line-height: 1.1;
    }

    .detail-text-value fw-bold text-gray-900 .icon {
      align-self: start;
      font-size: 0.75rem;
      margin: auto;
      margin-bottom: 4px;
    }

    .torque-col {
      min-width: 74px;
    }

    &:not(.header-row) .tightening-col {
      align-self: start;
    }
  }
}
