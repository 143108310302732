@use 'pkg:bootstrap';

.code-confirmation-modal {
  .is-invalid {
    border-color: bootstrap.$danger;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
  }

  .code-confirmation-note {
    height: 10rem;
  }
}
