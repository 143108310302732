@use 'sass:color';
@use 'pkg:bootstrap';

.plant-editor-areas-models {
  .utility-bar {
    background: color.adjust(bootstrap.$white, $lightness: 1%);
    border: 1px solid bootstrap.$border-color;
    border-radius: 3px;
    margin: 0 0 10px;
    padding: 5px 10px;

    .utilities {
      margin: 5px 0;
    }

    .fa-icons {
      margin-right: 3px;
    }
  }
}
